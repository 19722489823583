import React, { Component } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  FormGroup,
  Input,
  Col,
  Button,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import axios from "axios";
import Constants from "../variables/Constants";
import Modal from "react-awesome-modal";
import "../css/ToolTipStyle.css";
// import { Link } from "@material-ui/core";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faEye, faUser } from "@fortawesome/free-regular-svg-icons";
// import { Link } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  faCheck,
  faDownload,
  faTruck,
} from "@fortawesome/free-solid-svg-icons";
import "../css/ToolTipStyle.css";
import { UncontrolledAlert } from "reactstrap/lib";

const filterButtons = [
  {
    label: "All",
    value: "",
  },
  {
    label: "Order Placed",
    value: "order-placed",
  },
  { label: "Order Confirmed", value: "ORDER-CONFIRMED" },
  { label: "Shipped", value: "SHIPPED" },
  // { label: "PICKED UP", value: "PICKED UP" },
  { label: "Out for Delivery", value: "OUT FOR DELIVERY" },
  { label: "Order Cancelled", value: "CANCEL" },
  { label: "Delivered", value: "DELIVERED" },
];

const color_obj = {
  "order-placed": "btn-primary",
  "ORDER-CONFIRMED": " btn-info",
  SHIPPED: " btn-warning",
  "OUT FOR DELIVERY": " btn-success",
  DELIVERED: " btn-secondary",
  CANCEL: " btn-danger",
};
class Orders extends Component {
  state = {
    orders: [],
    filteredOrders: [],
    logistics: [],
    logic: "",
    image: "",
    description: "",
    trackingNo: "",
    delStatus: "order-placed",
    notifyStatus: false,
    loading: false,
    notification: false,
    empty: false,
    details: false,
    status: "",
    active: 1,
    act: 0,
    btnActive: 1,
    btnIdArray: [],
    perPage: "10",
    total: 0,
    from: 0,
    to: 0,
    fromInc: 1,
    toInc: 5,
    filteredBtnIdArray: [],
    copied: false,
    selectedOrder: "",
    selectedDeliveryStatus: "",
    delLoading: false,
    rowId: 0,
    id: "",
    delivery_status: "",
    orderStatusModal: false,
    refundModalDisplay: false,
    refund_modal_amount: 0,
    applyClass: "",
    notification: false,
    dropDown: false,
    orderId: "",
    displayDelivery: false,
    btn_operational: false,
    new_id: "",
    trackingPopUp: false,
    trackingNo: "",
  };

  // order_cancel_warning = (orders) => {
  //   orders.delivery_status === "CANCEL" ? <button>"Are you Sure"</button> : "";
  // };

  deliveryStatusColorCode = () => {
    if (this.state.delivery_status === "order-placed") {
      this.setState({ applyClass: "form-control text-info" });
    } else if (this.state.delivery_status === "ORDER-CONFIRMED") {
      this.setState({ applyClass: "form-control text-success" });
    } else if (this.state.delivery_status === "SHIPPED") {
      this.setState({ applyClass: "form-control text-info" });
    } else if (this.state.delivery_status === "OUT FOR DELIVERY") {
      this.setState({ applyClass: "form-control text-info" });
    } else if (this.state.delivery_status === "DELIVERED") {
      this.setState({ applyClass: "form-control text-success" });
    } else if (this.state.delivery_status === "CANCEL") {
      this.setState({ applyClass: "form-control text-danger" });
    }
  };
  componentDidMount() {
    this.getOrders();

    axios
      .get(
        Constants.getUrls.logistics +
          "?limit=20&skip=0&page=0" +
          "&is_delete=no"
      )
      .then((resp) => {
        this.setState({ logistics: resp.data.docs });
      });
  }
  getOrders = () => {
    axios
      .get(
        Constants.getUrls.orders_upd_api +
          "?limit=" +
          this.state.perPage +
          "&skip=0&page=" +
          this.state.active +
          "&order_id=" +
          this.state.new_id
      )
      .then((resp) => {
        this.deliveryStatusColorCode();
        let btnIdArray = [];
        for (let i = 1; i <= resp.data.pagination.totalPages; i++) {
          btnIdArray.push(i);
        }
        this.setState(
          {
            fromInc: 1,
            active: 1,
            toInc: 5,
            btnIdArray,
            orders: resp.data.docs[0].paginatedResults,
            filteredOrders: resp.data.docs[0].paginatedResults,
            total: resp.data.pagination.totalDocs,
            from: 1,
            to: resp.data.pagination.limit,
            // selling_price: resp.data.docs,
          },
          () => {
            let filteredBtnIdArray = this.state.btnIdArray.filter(
              (v) => v >= this.state.fromInc && v <= this.state.toInc
            );
            this.setState({
              filteredBtnIdArray,
            });
          }
        );
      });
  };
  handlePage = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        fromInc: 1,
        active: 1,
        toInc: 5,
      },
      () => {
        axios
          .get(
            Constants.getUrls.orders_upd_api +
              "?limit=" +
              this.state.perPage +
              "&skip=0&page=" +
              this.state.active +
              "&delivery_status=" +
              this.state.delStatus
          )
          .then((resp) => {
            console.log(resp.data, "orders_up_api");
            let btnIdArray = [];
            for (let i = 1; i <= resp.data.pagination.totalPages; i++) {
              btnIdArray.push(i);
            }
            this.setState(
              {
                btnIdArray,
                // orders: resp.data.docs,
                orders: resp.data.docs[0].paginatedResults,
                // filteredOrders: resp.data.docs,
                filteredOrders: resp.data.docs[0].paginatedResults,
                total: resp.data.pagination.totalDocs,
                from: 1,
                to: resp.data.pagination.limit,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({
                  filteredBtnIdArray,
                });
                console.log(btnIdArray, "btnIdArray");
                console.log(filteredBtnIdArray, "filteredBtnIdArray");
              }
            );
          });
      }
    );
  };
  handleOrderStatusModal = (sec, e) => {
    this.setState({
      id: sec.order_id,
      delivery_status: e.target.value,
      orderStatusModal: true,
    });
  };

  currentPage = (v) => {
    let commonUrl =
      Constants.getUrls.orders_upd_api +
      "?limit=" +
      this.state.perPage +
      "&skip=0&delivery_status=" +
      this.state.delStatus +
      "&page=";
    axios.get(commonUrl + v).then((resp) => {
      // console.log(resp.data);
      this.setState({
        // orders: resp.data.docs,
        orders: resp.data.docs[0].paginatedResults,

        // filteredOrders: resp.data.docs,
        filteredOrders: resp.data.docs[0].paginatedResults,

        // total: resp.data.totalDocs,
        total: resp.data.pagination.totalDocs,

        from: v * this.state.perPage - (this.state.perPage - 1),
        to:
          v * this.state.perPage > this.state.total
            ? this.state.total
            : v * this.state.perPage,
        active: v,
        currentPage: v,
      });
    });
  };

  prevPages = () => {
    this.setState(
      {
        fromInc: this.state.fromInc - 5,
        toInc: this.state.toInc - 5,
      },
      () => {
        let filteredBtnIdArray = this.state.btnIdArray.filter(
          (v) => v >= this.state.fromInc && v <= this.state.toInc
        );
        if (filteredBtnIdArray.length >= 1) {
          if (filteredBtnIdArray.length === 1) {
            this.setState(
              {
                fromInc: this.state.fromInc + 4,
                toInc: this.state.toInc + 4,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 2) {
            this.setState(
              {
                fromInc: this.state.fromInc + 3,
                toInc: this.state.toInc + 3,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 3) {
            this.setState(
              {
                fromInc: this.state.fromInc + 2,
                toInc: this.state.toInc + 2,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 4) {
            this.setState(
              {
                fromInc: this.state.fromInc + 1,
                toInc: this.state.toInc + 1,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else {
            this.setState({ filteredBtnIdArray });
          }
        } else {
          this.setState(
            {
              fromInc: this.state.fromInc + 5,
              toInc: this.state.toInc + 5,
            },
            () => {
              let filteredBtnIdArray = this.state.btnIdArray.filter(
                (v) => v >= this.state.fromInc && v <= this.state.toInc
              );
              this.setState({ filteredBtnIdArray });
            }
          );
        }
      }
    );
  };

  nextPages = () => {
    this.setState(
      {
        fromInc: this.state.fromInc + 5,
        toInc: this.state.toInc + 5,
      },
      () => {
        let filteredBtnIdArray = this.state.btnIdArray.filter(
          (v) => v >= this.state.fromInc && v <= this.state.toInc
        );
        if (filteredBtnIdArray.length >= 1) {
          if (filteredBtnIdArray.length === 1) {
            this.setState(
              {
                fromInc: this.state.fromInc - 4,
                toInc: this.state.toInc - 4,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 2) {
            this.setState(
              {
                fromInc: this.state.fromInc - 3,
                toInc: this.state.toInc - 3,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 3) {
            this.setState(
              {
                fromInc: this.state.fromInc - 2,
                toInc: this.state.toInc - 2,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 4) {
            this.setState(
              {
                fromInc: this.state.fromInc - 1,
                toInc: this.state.toInc - 1,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else {
            this.setState({ filteredBtnIdArray });
          }
        } else {
          this.setState(
            {
              fromInc: this.state.fromInc - 5,
              toInc: this.state.toInc - 5,
            },
            () => {
              let filteredBtnIdArray = this.state.btnIdArray.filter(
                (v) => v >= this.state.fromInc && v <= this.state.toInc
              );
              this.setState({ filteredBtnIdArray });
            }
          );
        }
      }
    );
  };
  handleSelect = (e, id) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        const payload = {
          status: this.state.status,
        };
        axios.post(Constants.postUrls.orderStatus + id, payload).then((res) => {
          // console.log(res);
          this.setState({
            notifyStatus: true,
          });
          setTimeout(() => {
            window.location.reload();
          }, 500);
        });
      }
    );
  };
  handleStatus = (value, index) => {
    axios
      .get(
        Constants.getUrls.orders_upd_api +
          "?limit=" +
          this.state.perPage +
          "&skip=0&page=" +
          this.state.active +
          "&delivery_status=" +
          value
      )

      .then((resp) => {
        console.log(resp, "upd api orders");
        let btnIdArray = [];
        for (let i = 1; i <= resp.data.pagination.totalPages; i++) {
          btnIdArray.push(i);
        }
        this.setState(
          {
            fromInc: 1,
            active: 1,
            toInc: 5,
            act: index,
            delStatus: value,
            btnIdArray,
            orders: resp.data.docs[0].paginatedResults,
            filteredOrders: resp.data.docs[0].paginatedResults,
            total: resp.data.pagination.totalDocs,
            from: 1,
            // to: resp.data.docs.length,
            to: resp.data.pagination.limit,
            // pagesCount: resp.data[0].totalCount[0]?.count,
          },
          () => {
            let filteredBtnIdArray = this.state.btnIdArray.filter(
              (v) => v >= this.state.fromInc && v <= this.state.toInc
            );
            this.setState({
              filteredBtnIdArray,
            });
            // console.log(this.state.pagesCount, "pg count");
          }
        );
      });
  };
  addShipping = () => {
    this.setState({ loading: true });
    let payLoad = {
      tracking_no: this.state.trackingNo,
      delivery_status: "shipped",
      logistic: this.state.logic,
    };
    axios
      .post(Constants.postUrls.shipOrder + this.state.orderId, payLoad)
      .then((resp) => {
        // console.log(resp);
        this.setState({
          notification: true,
          loading: false,
          visible: false,
        });
        setTimeout(() => {
          // window.location.reload();
        }, 1000);
      });
  };
  deliverOrder = (orderId) => {
    this.setState({ loading: true });
    let payLoad = {
      delivery_status: "delivered",
    };
    axios.post(Constants.postUrls.deliverOrder + orderId, payLoad).then(() => {
      //console.log(resp);
      this.setState({
        notification: true,
        loading: false,
      });
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    });
  };
  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  updateDeliveryStatus = (id, delivery_status) => {
    const payload = { delivery_status };
    axios
      .put(Constants.putUrls.updateDeliveryStatus + id, payload)
      .then((response) => {
        console.log(response);
        this.getOrders();
      });
  };

  refund = () => {
    axios
      .post(Constants.postUrls.refund + "/" + this.state.orderId)
      .then((res) => {
        if (res.data.status === "success") {
          this.setState({ notifyDelete: true, refundModalDisplay: false });
          this.getOrders();
        }
      });
  };

  shipping = () => {
    const payload = {
      delivery_status: "SHIPPED",
      tracking_no: this.state.trackingNo,
    };
    axios
      .put(Constants.putUrls.updateDeliveryStatus + this.state.orderId, payload)
      .then((response) => {
        console.log(response);
        this.getOrders();
      });
  };
  render() {
    return (
      <>
        {/* shipping pop up */}
        {this.state.trackingPopUp && (
          <>
            <div
              onClick={() => {
                this.setState({ trackingPopUp: false });
              }}
              className="position-fixed top-0 bottom-0 left-0 right-0"
              style={{ backgroundColor: "#808080a1", zIndex: "99" }}
            ></div>
            <div
              style={{
                height: "200px",
                width: "300px",
                zIndex: "999",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                boxShadow: "#6f6f6f 2px 2px 10px 3px",
              }}
              className="p-3 border bg-light position-fixed "
            >
              <div className="d-flex flex-column">
                <span>Tracking Number: </span>
                <input
                  onChange={(e) => {
                    this.setState({ trackingNo: e.target.value });
                  }}
                  className="mt-2"
                  type="text"
                  style={{
                    padding: "10px",
                    borderRadius: "10px",
                    border: "1px solid grey",
                    outline: "none",
                  }}
                />
              </div>
              <div className="d-flex justify-content-start mt-3">
                <button
                  onClick={() => {
                    this.shipping();
                    this.setState({ trackingPopUp: false });
                  }}
                  className="btn btn-success"
                >
                  Ship
                </button>
              </div>
            </div>
          </>
        )}
        <div className="content overflow-hidden bg-black">
          {this.state.notification ? (
            <div
              style={{
                width: "250px",
                position: "relative",
                left: "40%",
                top: "7px",
              }}
            >
              <UncontrolledAlert color="success" fade={false}>
                <span className="alert-inner--icon">
                  <i className="ni ni-like-2" />
                </span>{" "}
                <span className="alert-inner--text">
                  <strong>Order Status Changed Successfully!</strong>
                </span>
              </UncontrolledAlert>
            </div>
          ) : null}
          <Row className="bg-black">
            <Col md="12" className="bg-black">
              <Card className="bg-black">
                <FormGroup>
                  <div className="d-flex align-items-center col-md-5 mt-2">
                    <Input
                      width={"30%"}
                      type="text"
                      name="name"
                      value={this.state.name}
                      placeholder="*complete order ID is required!"
                      onChange={(e) => {
                        this.setState(
                          {
                            new_id: e.target.value,
                            // btn_operational:
                            //   e.target.value.length > 16 ? true : false,
                          },
                          this.getOrders()
                        );
                      }}
                      className="mr-2"
                    />
                  </div>
                </FormGroup>
                <FormGroup
                  style={{
                    position: "absolute",
                    top: "26px",
                    right: "2%",
                    width: "85px",
                  }}
                >
                  <label>Per Page</label>
                  <br />
                  <select
                    style={{ height: "2.8rem" }}
                    className="form-control"
                    name="perPage"
                    value={this.state.perPage}
                    onChange={this.handlePage}
                  >
                    {[10, 25, 50].map((category, i) => (
                      <option key={i} value={category}>
                        {category}
                      </option>
                    ))}
                  </select>
                </FormGroup>
                <CardHeader className="bg-black">
                  <CardTitle className="table-content-text" tag="h4">
                    ORDERS
                  </CardTitle>
                </CardHeader>

                <CardBody className="bg-black shadow-sm">
                  {/* /////// delivery status buttons /////// */}
                  <div className="d-flex align-items-center mb-3 p-2">
                    <div className="text-center">
                      <label style={{ fontSize: "17px" }}>Filters:</label>
                    </div>
                    <div>
                      {filterButtons.map((btn, index) => (
                        <button
                          className="button-paper"
                          style={{
                            marginRight: "20px",
                            marginLeft: "10px",
                          }}
                          color={this.state.act === index ? "light" : "dark"}
                          onClick={() => {
                            this.setState({ active: 1 }, () => {
                              this.handleStatus(btn.value, index);
                            });
                          }}
                        >
                          {btn.label}
                        </button>
                      ))}
                    </div>
                  </div>
                  {/*///////// content-section///////// */}
                  <div className="bg-black">
                    {this.state.filteredOrders.map((allorders, i) => (
                      <>
                        {/* //////// heading-table ///////// */}
                        <table className="table responsive bg-black borderless main-table">
                          <table className="tablesorter table bg-black borderless">
                            <thead className="borderless">
                              <tr className="table-head-row  d-flex align-items-center">
                                <th className="col-3">
                                  Customer Name: {allorders.orders[0].user.name}
                                </th>
                                <th className="col-3">
                                  Order ID: {allorders.orders[0].order_id}
                                </th>

                                <th className="col-3">
                                  <div id="wrapper">
                                    <header className="d-flex">
                                      <div
                                        class="iconDiv"
                                        tooltip="Show Orders"
                                        tabindex="0"
                                        onClick={() => {
                                          this.setState((prevState) => {
                                            this.setState({
                                              dropDown:
                                                prevState.orderId ===
                                                allorders._id
                                                  ? false
                                                  : true,
                                              orderId:
                                                prevState.orderId ===
                                                allorders._id
                                                  ? ""
                                                  : allorders._id,
                                            });
                                          });
                                          console.log(this.state.orderId);
                                        }}
                                      >
                                        <div class="iconSVG">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            class="h-6 w-6"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                            stroke-width="1"
                                          >
                                            <path
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                              d="M5 19a2 2 0 01-2-2V7a2 2 0 012-2h4l2 2h4a2 2 0 012 2v1M5 19h14a2 2 0 002-2v-5a2 2 0 00-2-2H9a2 2 0 00-2 2v5a2 2 0 01-2 2z"
                                            />
                                          </svg>
                                        </div>
                                      </div>
                                      {/* invoice */}
                                      <Link
                                        onClick={() => {
                                          let url =
                                            Constants.postUrls.downloadInvoice +
                                            allorders.orders[0].order_id;
                                          // window.open(url, '_blank');
                                          const timestamp =
                                            new Date().getTime();
                                          const downloadUrl = `${url}?timestamp=${timestamp}`;
                                          console.log(allorders.orders[0]._id);
                                          // window.location.href = url ;
                                          const newWindow = window.open(
                                            downloadUrl,
                                            "_blank"
                                          );
                                          newWindow.addEventListener(
                                            "load",
                                            () => {
                                              newWindow.setTimeout(() => {
                                                newWindow.close();
                                              }, 200); // Delay the closing of the window (adjust the timeout as needed)
                                            }
                                          );
                                        }}
                                      >
                                        <div
                                          class="iconDiv"
                                          tooltip="Invoice"
                                          tabindex="0"
                                        >
                                          <div class="iconSVG">
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              class="h-6 w-6"
                                              fill="none"
                                              viewBox="0 0 24 24"
                                              stroke="currentColor"
                                              stroke-width="1"
                                            >
                                              <path
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                                              />
                                            </svg>
                                          </div>
                                        </div>
                                      </Link>
                                      <div class="spacer"></div>
                                      <div class="divider"></div>
                                      {/* ///// order details/////// */}
                                      <Link
                                        to={
                                          "/admin/orderDetails/" +
                                          allorders.orders[0].order_id
                                        }
                                      >
                                        <div
                                          class="iconDiv"
                                          tooltip="Order Details"
                                          tabindex="0"
                                        >
                                          <div class="iconSVG">
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              class="h-6 w-6"
                                              fill="none"
                                              viewBox="0 0 24 24"
                                              stroke="currentColor"
                                              stroke-width="1"
                                            >
                                              <path
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                                              />
                                            </svg>
                                          </div>
                                        </div>
                                      </Link>
                                      {/* ///// user details ////// */}
                                      <Link
                                        to={
                                          "/admin/userDetails/" +
                                          allorders.orders[0].user._id
                                        }
                                      >
                                        <div
                                          class="iconDiv"
                                          tooltip="User Details"
                                          tabindex="0"
                                        >
                                          <div class="iconSVG">
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              class="h-6 w-6"
                                              fill="none"
                                              viewBox="0 0 24 24"
                                              stroke="currentColor"
                                              stroke-width="1"
                                            >
                                              <path
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                                              />
                                            </svg>
                                          </div>
                                        </div>
                                      </Link>
                                    </header>
                                  </div>
                                </th>

                                {/* delivery status */}
                                <th className="col-3 d-flex align-items-center">
                                  <FontAwesomeIcon
                                    className="font-awesome-icons delivery-truck px-3 cursor-pointer"
                                    icon={faTruck}
                                    onClick={() => {
                                      this.setState((prevState) => {
                                        this.setState({
                                          displayDelivery:
                                            prevState.orderId === allorders._id
                                              ? false
                                              : true,
                                          orderId:
                                            prevState.orderId === allorders._id
                                              ? ""
                                              : allorders._id,
                                        });
                                      });
                                      console.log(this.state.orderId);
                                    }}
                                  />

                                  <select
                                    style={{ width: 170 }}
                                    value={allorders.orders[0].delivery_status}
                                    // className={`form-control ${
                                    //   color_obj[
                                    //     allorders.orders[0].delivery_status
                                    //   ]
                                    // }`}
                                    className={`form-control select ml-2 ${
                                      this.state.displayDelivery &
                                      (this.state.orderId === allorders._id)
                                        ? "show-delivery"
                                        : "hide-delivery"
                                    }`}
                                    onChange={(e) => {
                                      if (e.target.value === "SHIPPED") {
                                        this.setState({
                                          trackingPopUp: true,
                                          orderId: allorders.orders[0].order_id,
                                        });
                                      }
                                      if (e.target.value === "CANCEL") {
                                        this.handleOrderStatusModal(
                                          allorders.orders[0],
                                          e
                                        );
                                      } else {
                                        this.updateDeliveryStatus(
                                          allorders.orders[0].order_id,
                                          e.target.value
                                        );
                                      }
                                      this.setState({ notification: true });
                                      setTimeout(() => {
                                        this.setState({
                                          notification: false,
                                        });
                                      }, 3000);
                                      // this.setState({
                                      //   id: orders._id,
                                      //   delivery_status: e.currentTarget.value,
                                      // });
                                    }}
                                    name=""
                                    id=""
                                  >
                                    {" "}
                                    {this.state.delStatus ===
                                      "ORDER-CONFIRMED" ||
                                    this.state.delStatus === "SHIPPED" ||
                                    this.state.delStatus ===
                                      "OUT FOR DELIVERY" ||
                                    this.state.delStatus === "DELIVERED" ||
                                    // this.state.delStatus === "PICKED UP" ||
                                    this.state.delStatus === "CANCEL" ? null : (
                                      <option
                                        className="text-success"
                                        value="order-placed"
                                      >
                                        Order Placed
                                      </option>
                                    )}
                                    {this.state.delStatus === "SHIPPED" ||
                                    this.state.delStatus ===
                                      "OUT FOR DELIVERY" ||
                                    this.state.delStatus === "DELIVERED" ||
                                    // this.state.delStatus === "PICKED UP" ||
                                    this.state.delStatus === "CANCEL" ? null : (
                                      <option value="ORDER-CONFIRMED">
                                        Order Confirmed
                                      </option>
                                    )}
                                    {this.state.delStatus ===
                                      "OUT FOR DELIVERY" ||
                                    this.state.delStatus === "DELIVERED" ||
                                    // this.state.delStatus === "PICKED UP" ||
                                    this.state.delStatus === "CANCEL" ? null : (
                                      <option value="SHIPPED">Shipped</option>
                                    )}
                                    {/* {this.state.delStatus === "OUT FOR DELIVERY" ||
                              this.state.delStatus === "DELIVERED" ||
                              this.state.delStatus === "CANCEL" ? null : (
                                <option value="PICKED UP">Picked Up</option>
                              )} */}
                                    {this.state.delStatus === "DELIVERED" ||
                                    this.state.delStatus === "CANCEL" ? null : (
                                      <option value="OUT FOR DELIVERY">
                                        Out for Delivery
                                      </option>
                                    )}
                                    {this.state.delStatus ===
                                    "DELIVERED" ? null : (
                                      <option
                                        // onClick={() => {
                                        //   this.order_cancel_warning(orders);
                                        // }}
                                        value="CANCEL"
                                      >
                                        Order Cancelled
                                      </option>
                                    )}
                                    {this.state.delStatus ===
                                    "CANCEL" ? null : (
                                      <option value="DELIVERED">
                                        Delivered
                                      </option>
                                    )}
                                  </select>

                                  {/* <button
                                className={`form-control w-50 mt-3 ${
                                  color_obj[allorders.orders[0].delivery_status]
                                }`}
                              >
                                {allorders.orders[0].delivery_status}
                              </button> */}
                                  {allorders.orders[0].delivery_status ===
                                    "CANCEL" &&
                                  allorders.orders[0].payment_mode ===
                                    "online" &&
                                  allorders.orders[0].payment_status ===
                                    "success"
                                    ? allorders.orders[0].refund_status !==
                                        "success" && (
                                        <button
                                          onClick={() => {
                                            console.log("click");
                                            this.setState({
                                              orderId:
                                                allorders.orders[0].order_id,
                                              refundModalDisplay: true,
                                              refund_modal_amount:
                                                allorders.orders[0]
                                                  .selling_price,
                                            });
                                          }}
                                          className="btn btn-success mt-2"
                                        >
                                          Refund Amount{" "}
                                        </button>
                                      )
                                    : ""}
                                </th>

                                {/* button */}

                                {/* <th>
                                  <div className="">
                                    <div className="order-details-container ">
                                      <span className="mr-3 custom-text-gradient">
                                        Delivery Status:{" "}
                                      </span>
                                      <button
                                        className={`form-control w-50 mt-3  ${
                                          color_obj[
                                            allorders.orders[0].delivery_status
                                          ]
                                        }`}
                                      >
                                        {allorders.orders[0].delivery_status}
                                      </button>
                                      {allorders.orders[0].delivery_status ===
                                        "CANCEL" &&
                                      allorders.orders[0].payment_mode ===
                                        "online" &&
                                      allorders.orders[0].payment_status ===
                                        "success"
                                        ? allorders.orders[0].refund_status !==
                                            "success" && (
                                            <button
                                              onClick={() => {
                                                console.log("click");
                                                this.setState({
                                                  orderId:
                                                    allorders.orders[0]
                                                      .order_id,
                                                  refundModalDisplay: true,
                                                  refund_modal_amount:
                                                    allorders.orders[0]
                                                      .selling_price,
                                                });
                                              }}
                                              className="btn btn-success mt-2"
                                            >
                                              Refund Amount{" "}
                                            </button>
                                          )
                                        : ""}
                                    </div>
                                  </div>
                                </th> */}
                              </tr>
                            </thead>
                          </table>
                        </table>

                        {/* ////// order-dropdown-details-table ///////// */}

                        {allorders.orders.map((orders, index) => (
                          <>
                            <table
                              className={`table responsive bg-black borderless main-table borderless ${
                                this.state.dropDown &&
                                orders.order_id === this.state.orderId
                                  ? "d-block"
                                  : "d-none"
                              }`}
                            >
                              <table className="tablesorter table bg-black borderless">
                                <thead className="borderless">
                                  <tr className="table-head-row  d-flex align-items-center">
                                    <th className="">{index + 1}.</th>
                                    <th>
                                      <img
                                        src={
                                          Constants.imgUrl +
                                          orders?.variant?.image[0]
                                        }
                                        alt=""
                                      />
                                    </th>
                                    <th className="">
                                      Product:{" "}
                                      {orders.product
                                        ? orders.product.name
                                        : null}
                                    </th>
                                    <th className="">
                                      Price: ₹{orders.mrp_price}
                                    </th>
                                    <th>Quantity: {orders.quantity}</th>
                                    <th className="col-3">
                                      Date:{" "}
                                      {Constants.days[
                                        new Date(
                                          allorders.orders[0].order_date
                                        ).getDay()
                                      ] +
                                        " " +
                                        new Date(
                                          allorders.orders[0].order_date
                                        ).getDate() +
                                        " " +
                                        Constants.month[
                                          new Date(
                                            allorders.orders[0].order_date
                                          ).getMonth()
                                        ] +
                                        " " +
                                        new Date(
                                          allorders.orders[0].order_date
                                        ).getFullYear() +
                                        ", " +
                                        new Date(
                                          allorders.orders[0].order_date
                                        ).getHours() +
                                        ":" +
                                        new Date(
                                          allorders.orders[0].order_date
                                        ).getMinutes()}
                                    </th>
                                    <th>
                                      Status:{" "}
                                      {allorders.orders[0].payment_status ===
                                      "pending" ? (
                                        <span style={{ color: "orange" }}>
                                          {allorders.orders[0].payment_status.toUpperCase()}
                                        </span>
                                      ) : allorders.orders[0].payment_status ===
                                        "success" ? (
                                        <span style={{ color: "green" }}>
                                          {allorders.orders[0].payment_status.toUpperCase()}
                                        </span>
                                      ) : (
                                        <span style={{ color: "red" }}>
                                          {allorders.orders[0].payment_status.toUpperCase()}
                                        </span>
                                      )}
                                    </th>
                                  </tr>
                                </thead>
                              </table>
                            </table>
                          </>
                        ))}

                        {/*////// 2nd column ///////// */}
                        {/* <div className="col-3 ">
                              <div className="order-details-container ">
                                <div className="custom-text-gradient">
                                  <span>Payment Status: </span>
                                  <span
                                    style={{ fontWeight: "bolder" }}
                                    className="col"
                                  >
                                    {allorders.orders[0].payment_status ===
                                    "pending" ? (
                                      <span style={{ color: "orange" }}>
                                        {allorders.orders[0].payment_status.toUpperCase()}
                                      </span>
                                    ) : allorders.orders[0].payment_status ===
                                      "success" ? (
                                      <span style={{ color: "green" }}>
                                        {allorders.orders[0].payment_status.toUpperCase()}
                                      </span>
                                    ) : (
                                      <span style={{ color: "red" }}>
                                        {allorders.orders[0].payment_status.toUpperCase()}
                                      </span>
                                    )}
                                  </span>
                                </div>
                                <div className="custom-text-gradient">
                                  <span>Delivery Charges: </span>
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                      color: "green",
                                    }}
                                  >
                                    ₹{allorders.orders[0].delivery_amount}
                                  </span>
                                </div>
                                <div className="custom-text-gradient">
                                  <span>Order Total: </span>
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                      color: "green",
                                    }}
                                  >
                                    ₹{allorders.orders[0].total}
                                  </span>
                                </div>
                                <div className="custom-text-gradient">
                                  <span>Payment Method: </span>
                                  <span style={{ fontWeight: "bold" }}>
                                    {allorders.orders[0].payment_mode}
                                  </span>
                                </div>
                                <div className="custom-text-gradient">
                                  <span>Wrapping:</span>{" "}
                                  <span style={{ fontWeight: "bold" }}>
                                    {allorders.orders[0].gift_wrap_status ||
                                      "NA"}
                                  </span>
                                </div>
                              </div>
                            </div> */}

                        {/* /////// 3rd column ////// */}
                        {/* <div style={{ fontSize: 12 }} className="col-3">
                              <div className=" order-details-container  d-flex ">
                                <CopyToClipboard
                                  text={
                                    allorders.orders[0].address?.first_name +
                                    " " +
                                    allorders.orders[0].address?.last_name +
                                    " " +
                                    allorders.orders[0].address?.mobile_no +
                                    " " +
                                    allorders.orders[0].address?.address_line1 +
                                    " " +
                                    allorders.orders[0].address?.address_line2 +
                                    " " +
                                    allorders.orders[0].address?.pincode
                                  }
                                  onCopy={() => {
                                    this.setState({
                                      copied: true,
                                      selectedOrder: allorders.orders[0]._id,
                                    });
                                    setTimeout(() => {
                                      this.setState({ copied: false });
                                    }, 3000);
                                  }}
                                >
                                  <FontAwesomeIcon
                                    className="mt-2 text-light"
                                    icon={
                                      this.state.copied &&
                                      this.state.selectedOrder ===
                                        allorders.orders[0]._id
                                        ? faCheck
                                        : faCopy
                                    }
                                  />
                                </CopyToClipboard>
                                <div
                                  className=" fw-bolder text-uppercase custom-text-gradient"
                                  style={{
                                    paddingLeft: "10px",
                                  }}
                                >
                                  {allorders.orders[0].address?.first_name}{" "}
                                  {allorders.orders[0].address?.last_name}
                                  {<br></br>}
                                  {allorders.orders[0].address?.mobile_no}{" "}
                                  {<br></br>}
                                  {
                                    allorders.orders[0].address?.address_line1
                                  }, {<br></br>}
                                  {
                                    allorders.orders[0].address?.address_line2
                                  }{" "}
                                  {allorders.orders[0].address?.city}
                                  <br />
                                  {allorders.orders[0].address?.pincode}
                                </div>
                              </div>
                            </div> */}
                        {/* //////// 4th column /////// */}
                      </>
                    ))}
                  </div>
                  {this.state.filteredOrders.length === 0 ? (
                    <div>
                      <h6
                        style={{
                          textAlign: "center",
                          marginTop: "30px",
                          fontSize: "14px",
                        }}
                      >
                        No Data Found!
                      </h6>
                    </div>
                  ) : this.state.empty ? null : (
                    <div style={{ marginTop: "20px" }}>
                      <div style={{ float: "left" }}>
                        Showing {this.state.from} to {this.state.to} of{" "}
                        {this.state.total} entries.
                      </div>

                      <div style={{ float: "right" }}>
                        <Pagination>
                          <PaginationItem>
                            <PaginationLink
                              onClick={this.prevPages}
                              tabIndex="-1"
                            >
                              <i className="fa fa-angle-left" />
                              <span className="sr-only">Previous</span>
                            </PaginationLink>
                          </PaginationItem>
                          {this.state.filteredBtnIdArray.map((v, i) => (
                            <>
                              {console.log(v)}
                              <PaginationItem
                                className={
                                  this.state.active === v ? "active" : ""
                                }
                                key={i}
                              >
                                <PaginationLink
                                  onClick={() => {
                                    this.currentPage(v);
                                  }}
                                >
                                  {v}
                                </PaginationLink>
                              </PaginationItem>
                            </>
                          ))}
                          <PaginationItem>
                            <PaginationLink onClick={this.nextPages}>
                              <i className="fa fa-angle-right" />
                              <span className="sr-only">Next</span>
                            </PaginationLink>
                          </PaginationItem>
                        </Pagination>
                      </div>
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal
            visible={this.state.visible}
            width="700"
            height="330"
            effect="fadeInUp"
            onClickAway={() => this.setState({ visible: false })}
          >
            <Row>
              <Col md="12">
                <Card>
                  <CardBody>
                    {this.state.details ? (
                      <Row>
                        <Col md="6">
                          {this.state.image ? (
                            <img
                              width="100%"
                              alt=""
                              src={Constants.imgUrl + this.state.image}
                            ></img>
                          ) : (
                            "Image Not Available"
                          )}
                        </Col>
                        <Col md="6">
                          {this.state.description ? (
                            <div>{this.state.description}</div>
                          ) : (
                            "Description Not Available"
                          )}
                        </Col>
                      </Row>
                    ) : (
                      <form>
                        <Row>
                          <Col md="6">
                            <label>Logistics</label>
                            <br />
                            <select
                              style={{ padding: "11px", width: "100%" }}
                              name="logic"
                              // value={this.state.logic}
                              onChange={this.handleInput}
                            >
                              <select value="">Select</select>
                              {this.state.logistics.map((v, i) => (
                                <option key={i} value={v._id}>
                                  {v.name}
                                </option>
                              ))}
                            </select>
                          </Col>
                          <Col xs={12} sm={12} md={6}>
                            <FormGroup>
                              <label>Tracking No</label>
                              <Input
                                placeholder="Tracking No"
                                name="trackingNo"
                                onChange={this.handleInput}
                                required={true}
                                type="text"
                              />
                            </FormGroup>
                          </Col>

                          <Col xs={12} sm={12} md={4}>
                            {this.state.notification ? (
                              <div>
                                <span style={{ color: "green" }}>
                                  Shipping Details Added Successfuly!
                                </span>
                              </div>
                            ) : null}
                            <Button
                              className=""
                              color="warning"
                              onClick={this.addShipping}
                            >
                              {this.state.loading ? "Adding..." : "Add"}
                            </Button>
                            <Button
                              className=""
                              color="danger"
                              onClick={() => {
                                this.setState({ visible: false });
                              }}
                            >
                              Cancel
                            </Button>
                          </Col>
                        </Row>
                      </form>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Modal>
          <Modal
            visible={this.state.orderStatusModal}
            width="600"
            height="100"
            effect="fadeInUp"
            onClickAway={() => this.setState({ visible: false })}
          >
            <Row>
              <Col xs={12} sm={12} md={12}>
                <Card>
                  <CardBody>
                    <div>
                      <h3 style={{ textAlign: "center" }}>
                        Are You Sure You Want to Cancel this Order? <br></br>
                        This Process is{" "}
                        <span className="text-warning"> Irreversible!</span>
                      </h3>
                      {this.state.notifyDelete ? (
                        <div>
                          <span style={{ color: "green" }}>
                            Order Status Changed Successfuly!
                          </span>
                        </div>
                      ) : null}
                      <Row
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <Col xs={12} sm={12} md={4}>
                          <br />
                          <Button
                            className=""
                            color="warning"
                            onClick={() => {
                              this.updateDeliveryStatus(
                                this.state.id,
                                this.state.delivery_status
                              );
                              this.setState({ orderStatusModal: false });
                            }}
                          >
                            {this.state.delLoading
                              ? "Changing Status..."
                              : "Yes"}
                          </Button>
                          <Button
                            className=""
                            color="success"
                            onClick={() => {
                              this.setState({ orderStatusModal: false });
                            }}
                          >
                            No
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Modal>
          <Modal
            visible={this.state.refundModalDisplay}
            width="600"
            height="100"
            effect="fadeInUp"
            onClickAway={() => this.setState({ visible: false })}
          >
            <Row>
              <Col xs={12} sm={12} md={12}>
                <Card>
                  <CardBody>
                    <div>
                      <h3 style={{ textAlign: "center" }}>
                        Are You Sure You Want to Refund ₹{" "}
                        {this.state.refund_modal_amount} ?
                      </h3>
                      {this.state.notifyDelete ? (
                        <div>
                          <span style={{ color: "green" }}>
                            Payment Refunded Successfuly!
                          </span>
                        </div>
                      ) : null}
                      <Row
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <Col xs={12} sm={12} md={4}>
                          <br />
                          <Button
                            className=""
                            color="warning"
                            onClick={() => {
                              this.refund();
                            }}
                          >
                            {this.state.delLoading
                              ? "Changing Status..."
                              : "Yes"}
                          </Button>
                          <Button
                            className=""
                            color="success"
                            onClick={() => {
                              this.setState({ refundModalDisplay: false });
                            }}
                          >
                            No
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Modal>
        </div>
      </>
    );
  }
}

export default Orders;
