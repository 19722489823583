import { faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import TableBodyOrganelle from "components/Organelles/TableBodyOrganelle";
import TableHeaderOrganelle from "components/Organelles/TableHeaderOrganelle";
import React, { useEffect, useState } from "react";
import {
  Button,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  Row,
  Table,
} from "reactstrap";
import Constants from "variables/Constants";
import routes from "../routes";
import NotificationOrganelle from "components/Organelles/NotificationOrganelle";

function AddSubAdmin() {
  const [data, setData] = useState([]);
  const [id, setId] = useState([]);
  const [role, setRole] = useState("");
  const [addNotification, setAddNotification] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [previlages, setPrevilages] = useState([
    {
      menuId: "",
      submenuId: [],
    },
  ]);
  const [title, setTitle] = useState("");
  const [addSubAdminNotification, setAddSubAdminNotification] = useState(false);
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [addUserPopUp, setAddUserPopUp] = useState(false);
  const [userExists, setUserExists] = useState(false);
  const [subAdminAlreadyExist, setSubAdminAlreadyExist] = useState(false);
  const [userId, setUserId] = useState("");

  useEffect(() => {
    console.log(userExists, "user exists");
  }, [userExists]);

  useEffect(() => {
    console.log(userId);
  }, [userId]);

  useEffect(() => {
    selectAll();
    // console.log(isChecked);
  }, [isChecked]);

  // useEffect(() => {}, [previlages]);

  const checkbox_handler = (e) => {
    const isSelected = e.target.checked;

    if (isSelected) {
      setId([...id, e.target.value]);
    } else {
      setId(
        id.filter((filteredItem) => {
          return filteredItem !== e.target.value;
        })
      );
    }
  };

  const selectAll = () => {
    if (isChecked) {
      setId(
        routes.map((item) => {
          return item.id;
        })
      );
    } else {
      setId([]);
    }
  };

  const addSubAdmin = () => {
    const payload = {
      name: name,
      mobile_no: mobile,
      email: email,
      menus: id,
    };
    // console.log(Constants.postUrls.subAdmin, "payload");
    axios.post(Constants.postUrls.subAdmin, payload).then((response) => {
      console.log(response, "sub admin response");
      if (response.data?.data?._id) {
        setUserId(response?.data?.data?._id);
      }
      if (response.data.status === "success") {
        setAddNotification(true);
        setTimeout(() => {
          setAddNotification(false);
          window.location.href = "/admin/subAdmin";
        }, 3000);
      }
      if (response.data.message === "Subadmin already exist.") {
        setSubAdminAlreadyExist(true);
        setTimeout(() => {
          setSubAdminAlreadyExist(false);
        }, 3000);
      }

      if (response.data.message === "User already exist.") {
        setAddUserPopUp(true);
        alert("User already exist.");
      } else if (response.data.errors.msg === "please enter name") {
        alert("Please enter name.");
      } else if (response.data.errors.msg === "please enter email") {
        alert("Please enter email.");
      } else if (response.data.errors.msg === "please enter mobile no") {
        alert("Please enter mobile number.");
      } else if (response.data.message === "Subadmin already exist.") {
        alert("Subadmin already exist.");
      }
    });
  };

  const updateExistingUser = () => {
    console.log(Constants.putUrls.updateUserAsSubadmin, "url");
    const payload = {
      name: name,
      mobile_no: mobile,
      email: email,
      menus: id,
    };
    axios
      .put(`${Constants.putUrls.updateUserAsSubadmin}/${userId}`, payload)
      .then((response) => {
        // console.log(response, "sub admin response");
        if (response.data.status === "success") {
          setAddNotification(true);
          setTimeout(() => {
            setAddNotification(false);
            window.location.href = "/admin/subAdmin";
          }, 3000);
        } else if (response.data.errors.msg === "please enter name") {
          alert("Please enter name.");
        } else if (response.data.errors.msg === "please enter email") {
          alert("Please enter email.");
        } else if (response.data.errors.msg === "please enter mobile no") {
          alert("Please enter mobile number.");
        }
      })
      .catch((error) => {
        alert("something went wrong");
      });
  };

  return (
    <>
      {addUserPopUp && (
        <>
          <div
            style={{
              position: "fixed",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              justifyContent: "center",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              height: "200px",
              width: "500px",
              boxShadow: "1px 1px 10px 10px #8080801c",
              backgroundColor: "white",
              zIndex: "10",
            }}
          >
            <p>
              {" "}
              User already exists! Add as
              <span
                style={{ fontWeight: "bolder", textTransform: "uppercase" }}
              >
                {" "}
                Sub Admin?
              </span>
            </p>
            <div className="d-flex align-items-center justify-content-center">
              <button
                className="mr-3 btn btn-danger"
                onClick={() => {
                  // delete_data();
                  // delete_menu();
                  updateExistingUser();

                  setAddUserPopUp(false);
                }}
              >
                Yes
              </button>
              <button
                className="btn btn-success"
                onClick={() => {
                  setAddUserPopUp(false);
                }}
              >
                No
              </button>
            </div>
          </div>
          <div
            style={{
              position: "fixed",
              top: "0",
              bottom: "0",
              left: "0",
              right: "0",
              backgroundColor: "#0000002e",
              zIndex: "9",
            }}
            onClick={() => {
              setAddUserPopUp(false);
            }}
          ></div>
        </>
      )}
      {subAdminAlreadyExist && (
        <NotificationOrganelle
          style={{
            backgroundColor: "#d16018",
            position: "fixed",
            color: "white",
            right: "10px",
            top: "10px",
            zIndex: "100",
          }}
          message={
            <>
              <span style={{ textTransform: "uppercase" }}>
                Sub Admin Already Exists!
              </span>
            </>
          }
        />
      )}
      {addNotification && (
        <NotificationOrganelle
          style={{
            backgroundColor: "#219d68",
            position: "fixed",
            color: "white",
            right: "10px",
            top: "10px",
            zIndex: "100",
          }}
          message={
            <>
              <span style={{ textTransform: "uppercase" }}>{name},</span>
              is now a Sub Admin!
            </>
          }
        />
      )}
      {addSubAdminNotification && (
        <NotificationOrganelle
          style={{
            backgroundColor: "#219d68",
            position: "fixed",
            color: "white",
            right: "10px",
            top: "10px",
          }}
          message={
            <>
              <span style={{ textTransform: "uppercase" }}>{name}</span>, Added
              as Sub Admin Successfully!
            </>
          }
        />
      )}
      <Card>
        <CardHeader>Add Sub Admin</CardHeader>
        <CardBody>
          <Row className="align-items-center">
            <Col md={12}>
              <Form>
                <div className="col-12 justify-content-between d-flex">
                  <div className="col-4">
                    <FormGroup>
                      <label htmlFor=""></label>
                      <Input
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                        placeholder="name"
                      ></Input>
                    </FormGroup>
                  </div>
                  <div className="col-4">
                    <FormGroup>
                      <label htmlFor=""></label>
                      <Input
                        type="number"
                        onChange={(e) => {
                          setMobile(e.target.value);
                        }}
                        placeholder="mobile"
                      ></Input>
                    </FormGroup>
                  </div>
                  <div className="col-4">
                    <FormGroup>
                      <label htmlFor=""></label>
                      <Input
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                        placeholder="email"
                      ></Input>
                    </FormGroup>
                  </div>
                </div>
              </Form>
            </Col>
          </Row>
          <div className="row my-4">
            <div className="col-3">
              <input
                className="mr-2"
                checked={isChecked ? true : false}
                onClick={() => {
                  // console.log("click");
                  setIsChecked(!isChecked);
                  selectAll();
                }}
                type="checkbox"
              />{" "}
              <span>Select All</span>
            </div>

            {routes.map((item) => (
              <div className="col-3">
                <input
                  className="mr-2"
                  checked={id.includes(item.id)}
                  value={item.id}
                  onClick={(e) => {
                    checkbox_handler(e);
                  }}
                  type="checkbox"
                />
                <span>{item.name}</span>
              </div>
            ))}
          </div>
          <button
            onClick={() => {
              // setAddNotification(true);
              // setMenuId(menu?._id);
              // setSubMenuId(menu?.submenu._id);

              addSubAdmin();
            }}
            className="btn btn-success"
          >
            Submit
          </button>
        </CardBody>
      </Card>
    </>
  );
}

export default AddSubAdmin;
