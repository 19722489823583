import React, { Component } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Button,
  FormGroup,
  Form,
  Input,
  UncontrolledAlert,
} from "reactstrap";
import axios from "axios";
import Constants from "../variables/Constants";
import { Redirect, Link } from "react-router-dom";
class AddTaxType extends Component {
  state = {
    name: "",
    value: "",
    redirect: false,
    warn: false,
    notification: false,
    loading: false,
    super_categories: [],
    super_category: "",
    categories: [],
    category: "",
    sub_categories: [],
    sub_category: "",
    __superCategories: [],
    __categories: [],
    __subCategories: [],
    superCategoryValue: "",
    categoryValue: "",
    subCategoryValue: "",
  };
  componentDidMount() {
    // this.getCategories();
    this.fetchSuperCategories();
    axios
      .get(
        Constants.getUrls.super_categories +
          "?limit=100" +
          "&skip=0&page=1" +
          "&is_delete=no"
      )
      .then((resp) => {
        // console.log(resp);
        this.setState({
          super_categories: resp.data.superCat.docs,
        });
      });
  }

  fetchSuperCategories = () => {
    axios
      .get(`${Constants.getUrls.super_categories}?is_delete=no`)
      .then((response) => {
        // console.log(response.data.superCat.docs);
        this.setState({
          __superCategories: response.data.superCat.docs,
        });
      });
  };

  fetchSubCategories = () => {
    axios
      .get(
        Constants.getUrls.subCategories +
          "?category=" +
          this.state.categoryValue
      )
      .then((response) => {
        console.log(response.data.docs);
        this.setState({
          __subCategories: response.data.docs,
          sub_categories: response.data.docs,
          sub_category: response.data.docs,
        });
      });
  };

  fetchCategories = () => {
    axios
      .get(
        Constants.getUrls.categories +
          "?super_category=" +
          this.state.superCategoryValue
      )
      .then((response) => {
        // console.log(response.data.docs);
        this.setState({
          __categories: response.data.docs,
        });
      });
  };

  // getCategories = () => {
  //   axios
  //     .get(
  //       Constants.getUrls.products +
  //         "?limit=" +
  //         this.state.perPage +
  //         "&skip=0&page=" +
  //         "&sort=true" +
  //         this.state.active +
  //         "&is_delete=no" +
  //         "&super_category=" +
  //         this.state.superCategoryValue +
  //         "&category=" +
  //         this.state.categoryValue +
  //         "&sub_category" +
  //         this.state.subCategoryValue
  //     )
  //     .then((resp) => {
  //       // console.log(resp);
  //       let btnIdArray = [];
  //       for (let i = 1; i <= resp.data.totalPages; i++) {
  //         btnIdArray.push(i);
  //       }
  //       this.setState(
  //         {
  //           fromInc: 1,
  //           active: 1,
  //           toInc: 5,
  //           btnIdArray,
  //           data: resp.data.docs,
  //           total: resp.data.totalDocs,
  //           from: 1,
  //           to: resp.data.docs.length,
  //         },
  //         () => {
  //           let filteredBtnIdArray = this.state.btnIdArray.filter(
  //             (v) => v >= this.state.fromInc && v <= this.state.toInc
  //           );
  //           this.setState({
  //             filteredBtnIdArray,
  //           });
  //         }
  //       );
  //     });
  // };

  handleCatgory = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        axios
          .get(
            Constants.getUrls.categories +
              "?limit=50&page=1&status=active" +
              `&super_category=` +
              this.state.super_category +
              "&is_delete=no"
          )
          .then((resp) => {
            // console.log(resp);
            this.setState({
              categories: resp.data.docs,
            });
          });
      }
    );
  };

  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleSubmit = (e) => {
    this.setState({
      loading: true,
    });
    e.preventDefault();
    let payLoad = {
      tax_type: this.state.name,
      value: this.state.value,
      // sub_category: this.state.subCategoryValue,
    };
    axios.post(Constants.getUrls.taxTypes, payLoad).then((resp) => {
      if (resp.data.status === "success") {
        setTimeout(() => {
          this.setState({
            redirect: true,
          });
        }, 1000);
        this.setState({
          notification: true,
          loading: false,
        });
      } else {
        this.setState({
          warn: true,
          loading: false,
        });
        setTimeout(() => {
          this.setState({
            warn: false,
          });
        }, 1000);
      }
    });
  };
  render() {
    if (this.state.redirect) {
      return <Redirect to={"/admin/taxtypes"} />;
    } else {
      return (
        <>
          <div className="content">
            {this.state.notification ? (
              <div
                style={{
                  width: "250px",
                  position: "relative",
                  left: "40%",
                  top: "7px",
                }}
              >
                <UncontrolledAlert color="success" fade={false}>
                  <span className="alert-inner--icon">
                    <i className="ni ni-like-2" />
                  </span>{" "}
                  <span className="alert-inner--text">
                    <strong>Tax Type Added!</strong>
                  </span>
                </UncontrolledAlert>
              </div>
            ) : null}
            {this.state.warn ? (
              <div
                style={{
                  width: "250px",
                  position: "relative",
                  left: "40%",
                  top: "7px",
                }}
              >
                <UncontrolledAlert color="danger" fade={false}>
                  <span className="alert-inner--icon">
                    <i className="ni ni-dislike-2" />
                  </span>{" "}
                  <span className="alert-inner--text">
                    <strong>Some Error Occured!</strong>
                  </span>
                </UncontrolledAlert>
              </div>
            ) : null}
            <Row>
              <Col md="12">
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">Add Tax Type</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Form onSubmit={this.handleSubmit}>
                      <Row>
                        {/* <Col className="pr-1" md="4">
                          <FormGroup
                            style={{
                              width: "300px",
                              paddingRight: "10px",
                            }}
                          >
                            <label>Super Category</label>
                            <br />
                            <select
                              style={{ height: "2.8rem" }}
                              className="form-control"
                              name="perPage"
                              value={this.state.superCategoryValue}
                              onChange={(e) => {
                                this.setState(
                                  { superCategoryValue: e.target.value },
                                  () => {
                                    // this.getCategories();
                                    this.fetchCategories();
                                  }
                                );
                              }}
                            >
                              <option value={""}>Select an Option</option>
                              {this.state.__superCategories.map(
                                (superCategory, i) => (
                                  <>
                                    <option key={i} value={superCategory._id}>
                                      {superCategory.title}
                                    </option>
                                  </>
                                )
                              )}
                            </select>
                          </FormGroup>
                        </Col> */}
                        {/* <Col className="pr-1" md="4">
                          <FormGroup
                            style={{
                              width: "300px",
                              paddingRight: "10px",
                            }}
                          >
                            <label> Category</label>
                            <br />
                            <select
                              style={{ height: "2.8rem" }}
                              className="form-control"
                              name="perPage"
                              value={this.state.categoryValue}
                              onChange={(e) => {
                                this.setState(
                                  { categoryValue: e.target.value },
                                  () => {
                                    this.fetchSubCategories();
                                  }
                                );
                              }}
                            >
                              <option value="">
                                {this.state.superCategoryValue.length > 0
                                  ? "Now Select a Category"
                                  : "Select Super Category First!"}
                              </option>
                              {this.state.__categories.map((category, i) => (
                                <>
                              
                                  <option key={i} value={category._id}>
                                    {category.name}
                                  </option>
                                </>
                              ))}
                            </select>
                          </FormGroup>
                        </Col> */}
                        {/* <Col className="pr-1" md="4">
                          <FormGroup
                            style={{
                              width: "300px",
                              paddingRight: "10px",
                            }}
                          >
                            <label>Sub Category</label>
                            <br />
                            <select
                              style={{ height: "2.8rem" }}
                              className="form-control"
                              name="perPage"
                              value={this.state.subCategoryValue}
                              onChange={(e) => {
                                this.setState(
                                  { subCategoryValue: e.target.value },
                                  () => {
                                    // this.getCategories();
                                  }
                                );
                              }}
                            >
                              <option value="">
                                {this.state.categoryValue.length > 0
                                  ? "Now Select a Category"
                                  : "Select Category First!"}{" "}
                              </option>
                              {this.state.__subCategories.map(
                                (subCategory, i) => (
                                  <>
                                    <option key={i} value={subCategory._id}>
                                      {subCategory.name}
                                    </option>
                                  </>
                                )
                              )}
                            </select>
                          </FormGroup>
                        </Col> */}
                        <Col className="pr-1" md="4">
                          <FormGroup
                            style={{
                              width: "300px",
                              paddingRight: "10px",
                            }}
                          >
                            <label>Tax Type</label>
                            <Input
                              placeholder="Tax Type"
                              name="name"
                              onChange={this.handleInput}
                              required={true}
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        <Col className="pr-1" md="4">
                          <FormGroup>
                            <label>Value</label>
                            <Input
                              placeholder="Value"
                              name="value"
                              onChange={this.handleInput}
                              required={true}
                              type="number"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="pr-1" md="4">
                          <Button
                            className="btn-round"
                            color="success"
                            type="submit"
                          >
                            {this.state.loading ? "Adding..." : "Add Tax Type"}
                          </Button>
                          <Link to="/admin/taxtypes">
                            <Button className="btn-round" color="danger">
                              Cancel
                            </Button>
                          </Link>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </>
      );
    }
  }
}

export default AddTaxType;
