import React, { Component } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Button,
  FormGroup,
  Form,
  Input,
  UncontrolledAlert,
} from "reactstrap";
import axios from "axios";
import Constants from "../variables/Constants";
import { Redirect, Link } from "react-router-dom";
import Modal from "react-awesome-modal";
import { Select } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd } from "@fortawesome/free-solid-svg-icons";

class UpdateBrand extends Component {
  state = {
    name: "",
    id: "",
    redirect: false,
    warn: false,
    notification: false,
    loading: false,
    image: "",
    modalVisible: false,
    // image Preview
    imageFile: "",
    photoUrl: "",
    uploadImgVisi: true,
    bannerFile: "",
    updatedBanner: "",
    updatedImage: "",
    banner_image: "",
  };
  componentDidMount() {
    let data = this.props.location.state.data;
    console.log(data, "prop data");
    this.setState(
      {
        name: data.title,
        id: data._id,
        uploaded_image: data.image ? data.image : "",
        brand: data,
        uploaded_banner_image: data.banner_image,
      },
      () => {}
    );
  }

  handleFile = (e) => {
    console.log(e.target.files[0]);
    this.setState({ [e.target.name]: e.target.files[0] });
    console.log(this.state.image, "image");
    console.log(this.state.banner_image, "banner image");
  };
  // image Preview handle
  handlePreview = (e) => {
    this.setState({ updatedImage: e.target.files[0] }, () => {
      console.log(this.state.updatedImage, "image file");
      if (this.state.updatedImage) {
        const reader = new FileReader();
        console.log(reader, "reader");

        reader.onload = () => {
          this.setState({ updatedImage: reader.result });
        };
        reader.readAsDataURL(this.state.updatedImage);
      }
    });
  };
  banner_preview = (e) => {
    this.setState({ updatedBanner: e.target.files[0] }, () => {
      console.log(this.state.updatedBanner, "updated banner");
      if (this.state.updatedBanner) {
        const reader = new FileReader();
        console.log(reader, "reader");
        reader.onload = () => {
          this.setState({ updatedBanner: reader.result });
        };
        reader.readAsDataURL(this.state.updatedBanner);
      }
    });

    // this.setState({ bannerFile: reader.result }, () => {
    //   console.log(this.state.bannerFile, "banner file");
    // });
  };
  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleSubmit = (e) => {
    this.setState({
      loading: true,
    });
    e.preventDefault();
    // let payLoad = {
    //   title: this.state.name,
    // };

    let fd = new FormData();

    console.log(this.state.name, "name");
    fd.append("title", this.state.name);
    if (typeof this.state.image === "object") {
      console.log(this.state.image, "image");
      fd.append("image", this.state.image);
    }
    if (typeof this.state.banner_image === "object") {
      console.log(this.state.banner_image, "banner image");
      fd.append("banner_image", this.state.banner_image);
    }

    axios
      .put(Constants.putUrls.updateBrand + "/" + this.state.id, fd)
      .then((resp) => {
        fd.delete("image");
        if (resp.data.status === "success") {
          setTimeout(() => {
            this.setState({
              redirect: true,
            });
          }, 1000);
          this.setState({
            notification: true,
            loading: false,
          });
        } else {
          this.setState({
            warn: true,
            loading: false,
          });
          setTimeout(() => {
            this.setState({
              warn: false,
            });
          }, 1000);
        }
      });
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={"/admin/brand"} />;
    } else {
      return (
        <>
          <div className="content">
            {this.state.notification ? (
              <div
                style={{
                  width: "250px",
                  position: "relative",
                  left: "40%",
                  top: "7px",
                }}
              >
                <UncontrolledAlert color="success" fade={false}>
                  <span className="alert-inner--icon">
                    <i className="ni ni-like-2" />
                  </span>{" "}
                  <span className="alert-inner--text">
                    <strong>Brand Updated!</strong>
                  </span>
                </UncontrolledAlert>
              </div>
            ) : null}
            {this.state.warn ? (
              <div
                style={{
                  width: "250px",
                  position: "relative",
                  left: "40%",
                  top: "7px",
                }}
              >
                <UncontrolledAlert color="danger" fade={false}>
                  <span className="alert-inner--icon">
                    <i className="ni ni-dislike-2" />
                  </span>{" "}
                  <span className="alert-inner--text">
                    {/* <strong>Some Error Occured!</strong> */}
                    <strong>This Brand Already Added!</strong>
                  </span>
                </UncontrolledAlert>
              </div>
            ) : null}
            <Row>
              <Col md="12">
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">Update Brand</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <div className="d-flex justify-content-between">
                      <div className="d-flex flex-column">
                        <div
                          className="col-5 d-flex align-items-center "
                          style={{ height: "fit-content" }}
                        >
                          <label>Name:</label>
                          <input
                            style={{
                              padding: "10px 20px",
                              border: "1px solid #8080802b",
                              marginLeft: "20px",
                              borderRadius: "20px",
                            }}
                            width={"100px"}
                            placeholder="Name"
                            value={this.state.name}
                            name="name"
                            onChange={this.handleInput}
                            required={true}
                            type="text"
                          />
                        </div>
                        <div>
                          <div className="d-flex">
                            <Button
                              onClick={this.handleSubmit}
                              className="btn-round"
                              color="info"
                              type="submit"
                            >
                              {this.state.loading ? "Updating..." : "Update"}
                            </Button>
                            <Link to="/admin/brand">
                              <Button className="btn-round" color="danger">
                                Cancel
                              </Button>
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="col-7">
                        <div className="d-flex justify-content-around position-relative">
                          <div className="d-flex flex-column align-items-center justify-content-center">
                            <div
                              className="d-flex align-items-center justify-content-center"
                              style={{
                                height: "100px",
                                width: "100px",
                                objectFit: "contain",
                                borderRadius: "50%",
                                padding: "10px",
                                border: "1px solid grey",
                              }}
                            >
                              <img
                                style={{
                                  height: "100px",
                                  width: "100px",
                                  objectFit: "cover",
                                  borderRadius: "50%",
                                }}
                                src={
                                  Constants.imgUrl + this.state.uploaded_image
                                }
                                alt="image"
                              />
                            </div>
                            <p className="mt-3">Uploaded Image</p>
                          </div>
                          <div>
                            <div
                              className="d-flex  align-items-center justify-content-center"
                              style={{
                                height: "100px",
                                width: "100px",
                                objectFit: "contain",
                                borderRadius: "50%",
                                padding: "10px",
                                border: "1px solid grey",
                              }}
                            >
                              {this.state.updatedImage ? (
                                <img
                                  style={{
                                    height: "100px",
                                    width: "100px",
                                    objectFit: "cover",
                                    borderRadius: "50%",
                                  }}
                                  src={this.state.updatedImage}
                                  alt="image"
                                />
                              ) : null}
                            </div>
                            <p className="mt-3">Image Preview</p>
                          </div>

                          <div
                            style={{
                              position: "absolute",
                              top: "-10px",
                              right: "90px",
                            }}
                          >
                            <div
                              className="d-flex align-items-center justify-content-center"
                              style={{
                                backgroundColor: "#8080804d",
                                height: "30px",
                                width: "30px",
                                cursor: "pointer",
                                borderRadius: "50%",
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faAdd}
                                style={{
                                  color: "white",
                                  position: "absolute",
                                  top: "7px",
                                  right: "6px",
                                  fontSize: "larger",
                                }}
                              />
                            </div>
                            <input
                              style={{
                                height: "30px",
                                width: "30px",
                                objectFit: "contain",
                                borderRadius: "50%",
                                position: "absolute",
                                top: "0px",
                                opacity: 0,
                              }}
                              type="file"
                              onChange={(e) => {
                                this.handleFile(e);
                                this.handlePreview(e);
                              }}
                              // required={true}
                              name="image"
                            />
                          </div>
                        </div>
                        <div className="d-flex justify-content-around position-relative">
                          <div className="d-flex flex-column justify-content-center align-items-center">
                            <div className="d-flex flex-column justify-content-center align-items-center">
                              <img
                                style={{
                                  height: "100px",
                                  width: "100px",
                                  objectFit: "cover",
                                  borderRadius: "50%",
                                }}
                                src={
                                  Constants.imgUrl +
                                  this.state.uploaded_banner_image
                                }
                                alt=""
                              />
                            </div>
                            <p className="mt-3">Uploaded Banner</p>
                          </div>
                          <div className="d-flex flex-column align-items-center justify-content-center">
                            <div
                              className="d-flex  align-items-center justify-content-center"
                              style={{
                                height: "100px",
                                width: "100px",
                                objectFit: "contain",
                                borderRadius: "50%",
                                padding: "10px",
                                border: "1px solid grey",
                              }}
                            >
                              {this.state.updatedBanner ? (
                                <img
                                  style={{
                                    height: "100px",
                                    width: "100px",
                                    objectFit: "cover",
                                    borderRadius: "50%",
                                  }}
                                  src={this.state.updatedBanner}
                                  alt=""
                                />
                              ) : null}
                            </div>
                            <p className="mt-3">Banner Preview</p>
                          </div>
                          <div
                            style={{
                              position: "absolute",
                              top: "-10px",
                              right: "90px",
                            }}
                          >
                            <div
                              className="d-flex align-items-center justify-content-center"
                              style={{
                                backgroundColor: "#8080804d",
                                height: "30px",
                                width: "30px",
                                cursor: "pointer",
                                borderRadius: "50%",
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faAdd}
                                style={{
                                  color: "white",
                                  position: "absolute",
                                  top: "7px",
                                  right: "6px",
                                  fontSize: "larger",
                                }}
                              />
                            </div>
                            <input
                              style={{
                                height: "30px",
                                width: "30px",
                                objectFit: "contain",
                                borderRadius: "50%",
                                position: "absolute",
                                top: "0px",
                                opacity: 0,
                              }}
                              onChange={(e) => {
                                this.handleFile(e);
                                // this.setState(this.banner_preview(e));
                                this.banner_preview(e);
                              }}
                              type="file"
                              name="banner_image"
                            />
                          </div>
                        </div>
                      </div>{" "}
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </>
      );
    }
  }
}

export default UpdateBrand;
