import React, { Component } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Button,
  FormGroup,
  Form,
  Input,
  UncontrolledAlert,
} from "reactstrap";
import axios from "axios";
import Constants from "../variables/Constants";
import { Redirect, Link } from "react-router-dom";
import Modal from "react-awesome-modal";
import { PhotoshopPicker, SketchPicker } from "react-color";
import { GetColorName } from "hex-color-to-color-name";

class UpdateColor extends Component {
  state = {
    name: "",
    color: "",
    id: "",
    redirect: false,
    warn: false,
    notification: false,
    loading: false,
    image: "",
    modalVisible: false,
    colorCode: "",
    colorName: "",
    // data: [],
  };
  componentDidMount() {
    let data = this.props.location.state.data;
    console.log(data, "ptops");
    this.setState({
      name: data.title,
      id: data._id,
      colorName: data.color,
      colorCode: data.color_code,
      // image: data.image ? data.image : "",
    });
  }
  handleChangeComplete = (color) => {
    this.setState({
      colorCode: color.hex,
      // colorName: GetColorName(color.hex),
    });
    console.log(this.state.colorCode, "color code");
  };
  handleFile = (e) => {
    this.setState({ [e.target.name]: e.target.files[0] });
    console.log(this.state.image);
  };
  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleSubmit = (e) => {
    this.setState({
      loading: true,
    });
    e.preventDefault();
    // let payLoad = {
    //   title: this.state.name,
    // };
    let fd = new FormData();
    fd.append("color", this.state.colorName);
    fd.append("color_code", this.state.colorCode);
    // fd.append("title", this.state.name);
    // fd.append("image", this.state.image);
    axios
      .put(Constants.getUrls.updateColor + "/" + this.state.id, fd)
      .then((resp) => {
        fd.delete("image");
        if (resp.data.status === "success") {
          setTimeout(() => {
            this.setState({
              redirect: true,
            });
          }, 1000);
          this.setState({
            notification: true,
            loading: false,
          });
        } else {
          this.setState({
            warn: true,
            loading: false,
          });
          setTimeout(() => {
            this.setState({
              warn: false,
            });
          }, 1000);
        }
      });
  };
  render() {
    if (this.state.redirect) {
      return <Redirect to={"/admin/color"} />;
    } else {
      return (
        <>
          <div className="content">
            {this.state.notification ? (
              <div
                style={{
                  width: "250px",
                  position: "relative",
                  left: "40%",
                  top: "7px",
                }}
              >
                <UncontrolledAlert color="success" fade={false}>
                  <span className="alert-inner--icon">
                    <i className="ni ni-like-2" />
                  </span>{" "}
                  <span className="alert-inner--text">
                    <strong>Color Updated!</strong>
                  </span>
                </UncontrolledAlert>
              </div>
            ) : null}
            {this.state.warn ? (
              <div
                style={{
                  width: "250px",
                  position: "relative",
                  left: "40%",
                  top: "7px",
                }}
              >
                <UncontrolledAlert color="danger" fade={false}>
                  <span className="alert-inner--icon">
                    <i className="ni ni-dislike-2" />
                  </span>{" "}
                  <span className="alert-inner--text">
                    {/* <strong>Some Error Occured!</strong> */}
                    <strong>This Color Already Added!</strong>
                  </span>
                </UncontrolledAlert>
              </div>
            ) : null}
            <Row>
              <Col md="12">
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">Update Color</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Form onSubmit={this.handleSubmit}>
                      <div className="col-3">
                        <SketchPicker
                          color={this.state.colorCode}
                          onChangeComplete={this.handleChangeComplete}
                        />
                      </div>
                      <div className="col-5 d-flex flex-column justify-content-center">
                        <Row>
                          <Col className="pr-1" md="6">
                            <FormGroup>
                              <label htmlFor="">Code: </label>
                              <input
                                required
                                value={this.state.colorCode}
                                name="color_code"
                                placeholder="#000000"
                                onChange={(e) => {
                                  this.setState({ colorCode: e.target.value });
                                }}
                              />
                            </FormGroup>
                            <FormGroup>
                              <label>Name: </label>
                              <input
                                placeholder={this.state.color}
                                value={this.state.colorName}
                                name={this.state.color}
                                onChange={(e) => {
                                  console.log(e.target.value);
                                  this.setState(
                                    { colorName: e.target.value },
                                    () => {
                                      // this.handleInput(e);
                                      // console.log(this.state.colorName);
                                    }
                                  );
                                }}
                                required={true}
                                type="text"
                              />
                            </FormGroup>
                          </Col>
                          {/* ////// Sketch Picker /////// */}

                          {/* <Col>
                          <FormGroup>
                            <label>Description</label>
                            <Input
                              placeholder="Description"
                              value={this.state.description}
                              name="description"
                              onChange={this.handleInput}
                              required={true}
                              type="text"
                            />
                          </FormGroup>
                        </Col> */}
                          {/* <Col className="pr-1" md="6">
                          <label>Image</label>
                          <br />
                          <input
                            type="file"
                            name="image"
                            onChange={this.handleFile}
                            // required={true}
                            accept="image/*"
                          />
                        </Col>
                        <Col className="pr-1" md="6">
                            <label>Uploaded Image</label>
                            <br />
                            <img
                              src={Constants.imgUrl + this.state.image}
                              alt="image"
                            />
                          </Col>  */}
                        </Row>

                        {/* ////// buttons /////// */}
                        <Row>
                          <Col className="pr-1 d-flex" md="6">
                            <Button
                              onClick={this.handleSubmit}
                              className="btn-round"
                              color="success"
                              type="submit"
                            >
                              {this.state.loading
                                ? "Adding..."
                                : "Update Color"}
                            </Button>
                            {/* <Button
                            onClick={() => {
                              this.setState({ editFunctionality: true });
                            }}
                          >
                            Edit Name
                          </Button> */}
                            <Link to="/admin/color">
                              <Button className="btn-round" color="danger">
                                Cancel
                              </Button>
                            </Link>
                          </Col>
                        </Row>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </>
      );
    }
  }
}

export default UpdateColor;
