import React, { Component } from 'react'
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col,
    Button,
    FormGroup,
    Form,
    Input,
    UncontrolledAlert,
} from "reactstrap";
import axios from "axios";
import Constants from "../variables/Constants";
import { Redirect, Link } from "react-router-dom";

export default class AddReason extends Component {
    state = {
        name: "",
        description:"",
        redirect: false,
        warn: false,
        notification: false,
        loading: false,
        data: [],
        loading: false,
        super_categories: [],
        super_category: "",
        categories: [],
        category: "",
    };

    componentDidMount() {// API of Super Category
        axios
            .get(Constants.getUrls.super_categories + "?limit=100" + "&skip=0&page=1" + "&is_delete=no")
            .then((resp) => {
                // console.log("super" ,resp);
                this.setState({
                    super_categories: resp.data.superCat.docs,
                });
            });
    }

    handleCategory = (e) => {
        console.log(e.target.value)
        this.setState(
            {
                [e.target.name]: e.target.value,
            },
            () => {
                axios
                    .get(
                        Constants.getUrls.categories +
                        "?limit=50&page=1&status=active" +
                        `&super_category=` +
                        this.state.super_category + "&is_delete=no"
                    )
                    .then((resp) => {
                        console.log("resp", resp.data);
                        this.setState({
                            categories: resp.data.docs,
                        });
                    });
            }
        );
    };

    handleInput = (e) => {
        this.setState({
          [e.target.name]: e.target.value,
        });
      };

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({
            loading: true,
          });
          e.preventDefault();
          let payLoad = {
            super_category: this.state.super_category,
            category: this.state.category,
            title: this.state.name,
            description: this.state.description
          };
          console.log(payLoad)
      axios.post(Constants.getUrls.reasons, payLoad).then((resp) => {
        if(resp.data.status === "success"){
            setTimeout(() => {
                this.setState({
                    redirect: true,
                });
            },1000);
            this.setState({
                notification: true,
                loading: false
            });
        }else {
            this.setState({
                warn: true,
                loading:false
            });
            setTimeout(() => {
                this.setState({
                    warn: false,
                });
            },1000)
        }
      })  
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to={"/admin/reasons"} />;
          } else {
        return (
            <>
                <div className="content">
                    {this.state.notification ? (
                        <div
                            style={{
                                width: "250px",
                                position: "relative",
                                left: "40%",
                                top: "7px",
                            }}
                        >
                            <UncontrolledAlert color="success" fade={false}>
                                <span className="alert-inner--icon">
                                    <i className="ni ni-like-2" />
                                </span>{" "}
                                <span className="alert-inner--text">
                                    <strong>Reasons Added!</strong>
                                </span>
                            </UncontrolledAlert>
                        </div>
                    ) : null}
                    {this.state.warn ? (
                        <div
                            style={{
                                width: "250px",
                                position: "relative",
                                left: "40%",
                                top: "7px",
                            }}
                        >
                            <UncontrolledAlert color="danger" fade={false}>
                                <span className="alert-inner--icon">
                                    <i className="ni ni-dislike-2" />
                                </span>{" "}
                                <span className="alert-inner--text">
                                <strong>Reason With This Name Already Exists!</strong>
                                    {/* <strong>Some Error Occured!</strong> */}
                                </span>
                            </UncontrolledAlert>
                        </div>
                    ) : null}
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader>
                                    <CardTitle tag="h4">Add Reasons</CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <Form onSubmit={this.handleSubmit}>
                                        <Row>
                                            <Col className="pr-1" md="6">
                                                <FormGroup>
                                                    <label>Super Category</label>
                                                    <br />
                                                    <select
                                                        className="form-control"
                                                        name="super_category"
                                                        required={true}
                                                        onChange={this.handleCategory}
                                                    >
                                                        <option value="">Select an Option</option>
                                                        {this.state.super_categories.map((v, i) => (
                                                            <option key={i} value={v._id}>
                                                                {v.title}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </FormGroup>
                                            </Col>
                                            <Col className="pr-1" md="6">
                                                <FormGroup>
                                                    <label>Category</label>
                                                    <br />
                                                    <select
                                                        className="form-control"
                                                        name="category"
                                                        required={true}
                                                        onChange={this.handleInput}
                                                    >
                                                        <option value="">Select an Option</option>
                                                        {this.state.categories.map((v, i) => (
                                                            <option key={i} value={v._id}>
                                                                {v.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </FormGroup>
                                            </Col>

                                            <Col className="pr-1" md="6">
                                                <FormGroup>
                                                    <label>Name</label>
                                                    <Input
                                                        placeholder="Name"
                                                        name="name"
                                                        onChange={this.handleInput}
                                                        required={true}
                                                        type="text"
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col className="pr-1" md="6">
                                                <FormGroup>
                                                    <label>Description</label>
                                                    <Input
                                                        placeholder="Description"
                                                        name="description"
                                                        onChange={this.handleInput}
                                                        required={true}
                                                        type="text"
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="pr-1" md="6">
                                                <Button
                                                    className="btn-round"
                                                    color="success"
                                                    type="submit"
                                                >
                                                    {this.state.loading ? "Adding..." : "Add Reasons"}
                                                </Button>
                                                <Link to="/admin/reasons">
                                                    <Button className="btn-round" color="danger">
                                                        Cancel
                                                    </Button>
                                                </Link>
                                            </Col>
                                        </Row>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        )
    }
}
}