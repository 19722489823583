// const mainDomain = "https://node.thespaceelement.com:4005/";

const mainDomain = "https://core.destore.co.in/";

// const mainDomain = "https://coretest.crazenchase.com:3001/";
//
// const mainDomain = "http://192.168.1.43:3000/";
const Constants = {
  // imgUrl: "https://coretest.crazenchase.com:3001/",
  imgUrl: "https://core.destore.co.in",
  days: [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ],
  month: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ],

  statusUrl: mainDomain + "status/",
  getUrls: {
    getInventory: mainDomain + "variant/inventory",
    getPaymentImgSliders: mainDomain + "paymentImgSlider",
    deletePaymentImgSliders: mainDomain + "paymentImgSlider",
    updatePaymentImgSliders: mainDomain + "paymentImgSlider",
    user: mainDomain + "user",
    orders_upd_api: mainDomain + "order/getAllOrder",
    singleVariant: mainDomain + "variant/getSingle",
    getSize: mainDomain + "size",
    deleteSize: mainDomain + "size",
    updateSize: mainDomain + "size",

    getColor: mainDomain + "color",
    deleteColor: mainDomain + "color",
    updateColor: mainDomain + "color",

    ourInvestors: mainDomain + "investors",
    our_categories: mainDomain + "investors",
    //deleverypartner===========
    deliveryPartners: mainDomain + "deliveryPartner",
    singleSubAdmin: mainDomain + "user/singleSubAdmin",
    deleteDeliveryPartners: mainDomain + "deliveryPartner",
    updateDeliveryPartners: mainDomain + "deliveryPartner",

    productSearch: mainDomain + "best_seller/prodSearch",
    BestproductSearch: mainDomain + "best_seller/productSearch",
    categories: mainDomain + "category",
    super_categories: mainDomain + "super_category",
    brand: mainDomain + "brand",
    deleteBrand: mainDomain + "brand",
    mobileHomeSliders: mainDomain + "mobile_home_slider",
    orders: mainDomain + "order",
    subAdmin: mainDomain + "user/getAllMenus",
    getRating: mainDomain + "rating_and_review",
    taxTypes: mainDomain + "tax_type",
    pincodes: mainDomain + "pincode",
    ads: mainDomain + "ad",
    subCategories: mainDomain + "sub_category",
    printTypes: mainDomain + "print_type",
    products: mainDomain + "product",
    singleSubAdmin: mainDomain + "user/subAdminById",
    vendors: mainDomain + "vendor",
    deliveryModes: mainDomain + "deliveryMode",
    coupons: mainDomain + "coupon",
    logistics: mainDomain + "logistic",
    homePages: mainDomain + "homePage",
    homeSliders: mainDomain + "homeSlider",
    bestSellers: mainDomain + "best_seller",
    vendorCommission: mainDomain + "vendorCommission",
    productsFor: mainDomain + "product_for",
    bulkOrders: mainDomain + "bulk_product",
    contactUs: mainDomain + "contactUs",
    bulkProducts: mainDomain + "bulk_order",
    getVariants: mainDomain + "variant",
    showUserKYC: mainDomain + "kyc",

    reasons: mainDomain + "reason",
    dashboard: mainDomain + "dashboard",

    addSettings: mainDomain + "setting",
    menu: mainDomain + "menu",
    submenu: mainDomain + "submenu",
    role: mainDomain + "roles",
  },
  postUrls: {
    addTopBrands: mainDomain + "brand/setTopBrand",
    addPaymentImgSliders: mainDomain + "paymentImgSlider",

    addSize: mainDomain + "size",

    addColor: mainDomain + "color",

    addSettings: mainDomain + "setting/addUpdate",

    addDeliveryPartners: mainDomain + "deliveryPartner",
    deleteOurInvestors: mainDomain + "investors",
    setTrending: mainDomain + "product/setTrendingProduct/",
    setBestSeller: mainDomain + "product/setBestsellerProduct/",

    shipOrder: mainDomain + "orderStatus/order_shipped/",
    searchProduct: mainDomain + "product/pname",
    searchProductCoupon: mainDomain + "product/search",
    addOurInvestors: mainDomain + "investors",
    deliverOrder: mainDomain + "orderStatus/delivery_status/",
    downloadCsv: mainDomain + "user/exportExcel",
    downloadCsvProduct: mainDomain + "product/exportExcel",

    downloadInvoice: mainDomain + "order/download_invoice/",
    importCsv: mainDomain + "pincode/cvs",
    login: mainDomain + "auth/dashboard",
    postVariants: mainDomain + "variant",
    postPincode: mainDomain + "pincode",
    sendSms: mainDomain + "user/send-bulk-sms",
    addBrand: mainDomain + "brand",
    addMenu: mainDomain + "menu/create",
    addSubmenu: mainDomain + "submenu/create",
    statusUpdate: mainDomain + "status/",
    addRole: mainDomain + "roles/create",
    password: mainDomain + "user/reset_pass",
    subAdmin: mainDomain + "user/createSubAdmin",
  },
  putUrls: {
    updateDeliveryStatus: mainDomain + "orderStatus/update_delivery_status/",
    updateOurInvestors: mainDomain + "investors",
    updateBrand: mainDomain + "brand",
    variant: mainDomain + "variant",
    addVariantImage: mainDomain + "variant/addImage/",
    updateMenu: mainDomain + "menu/update/",
    statusUpdate: mainDomain + "status/",
    updateRole: mainDomain + "roles/update/",
    updateSubmenuStatus: mainDomain + "status/",
    updateSubmenu: mainDomain + "submenu/update/",
    updateSubAdmin: mainDomain + "user/updateSubAdmin",
    updateUserAsSubadmin: mainDomain + "user/updateUSerRole",
  },
  deleteUrls: {
    variant: mainDomain + "variant",
    menu: mainDomain + "menu/",
    submenu: mainDomain + "submenu/",
    subAdmin: mainDomain + "user/deleteSubAdmin/",
  },
};
export default Constants;
