import { faEye, faEyeSlash, faGear } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import axios from "axios";
import Constants from "variables/Constants";

function Password() {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [showPassword3, setShowPassword3] = useState(false);

  useEffect(() => {
    console.log(localStorage.key(0));
  }, []);

  const change_password = () => {
    if (oldPassword !== oldPassword) {
      alert("Old Password do not match!");
      return;
    }

    if (oldPassword === newPassword) {
      alert("Old Password and New Password are Same!");
      return;
    }
    if (confirmPassword !== newPassword) {
      alert("confirm password not matches the new Password!");
      return;
    }
    if (oldPassword === "") {
      alert("old password field is blank!");
      return;
    }
    if (newPassword === "") {
      alert("new password field is blank!");
      return;
    }
    if (confirmPassword === "") {
      alert("Confirm password field is blank!");
      return;
    }
    if (oldPassword === newPassword) {
      alert("Old Password and New Password are Same!");
      return;
    }

    const payload = {
      old_pass: oldPassword,
      new_pass: newPassword,
      confirm_pass: confirmPassword,
    };
    axios.post(Constants.postUrls.password, payload).then((response) => {
      console.log(response.data, "password change response");
      if (response.data.status === "success") {
        alert("Password Changed Succesfully!");
        setOldPassword(newPassword);
        window.location.href = "/";
        localStorage.clear();
      } else if (response.data.message === "Your old password do not match.") {
        alert("Your old password do not match.");
      }
    });
  };

  return (
    <div
      className="d-flex align-items-center justify-content-center"
      style={{ height: "100vh" }}
    >
      <div
        className="d-flex flex-column p-3"
        style={{
          height: "300px",
          width: "420px",
          backgroundColor: "#bc8d83ee",
        }}
      >
        <div style={{ width: "100%" }} className="position-relative">
          <p
            style={{ fontSize: "small", fontWeight: "normal" }}
            className="text-dark mb-0"
          >
            Old Password:
          </p>
          <input
            style={{
              width: "inherit",
              padding: "4px 30px 4px 10px",
              fontSize: "small",
            }}
            onChange={(e) => {
              setOldPassword(e.target.value);
            }}
            className="mb-3"
            type={showPassword ? "text" : "password"}
          ></input>
          <FontAwesomeIcon
            style={{
              position: "absolute",
              right: "10px",
              top: "30px",
              fontSize: "small",

              cursor: "pointer",
            }}
            icon={showPassword ? faEye : faEyeSlash}
            onClick={() => {
              setShowPassword(!showPassword);
            }}
          />
        </div>

        <div style={{ width: "100%" }} className="position-relative">
          <p
            style={{ fontSize: "small", fontWeight: "normal" }}
            className="text-dark mb-0"
          >
            New Password:
          </p>
          <input
            style={{
              width: "inherit",
              padding: "4px 30px 4px 10px",
              fontSize: "small",
            }}
            onChange={(e) => {
              setNewPassword(e.target.value);
            }}
            className="mb-3 "
            type={showPassword2 ? "text" : "password"}
          ></input>
          <FontAwesomeIcon
            style={{
              position: "absolute",
              right: "10px",
              top: "30px",
              fontSize: "small",

              cursor: "pointer",
            }}
            icon={showPassword2 ? faEye : faEyeSlash}
            onClick={() => {
              setShowPassword2(!showPassword2);
            }}
          />
        </div>

        <div style={{ width: "100%" }} className="position-relative">
          <p
            style={{ fontSize: "small", fontWeight: "normal" }}
            className="text-dark mb-0"
          >
            Confirm Password:
          </p>
          <input
            style={{
              width: "inherit",
              padding: "4px 30px 4px 10px",
              fontSize: "small",
            }}
            onChange={(e) => {
              setConfirmPassword(e.target.value);
            }}
            className="mb-3"
            type={showPassword3 ? "text" : "password"}
          ></input>
          <FontAwesomeIcon
            style={{
              position: "absolute",
              right: "10px",
              top: "30px",
              fontSize: "small",

              cursor: "pointer",
            }}
            icon={showPassword3 ? faEye : faEyeSlash}
            onClick={() => {
              setShowPassword3(!showPassword3);
            }}
          />
        </div>

        <button
          style={{ backgroundColor: "#663300", border: "1px solid #663300" }}
          onClick={() => {
            // change_password();
          }}
          className="btn btn-warning mt-2"
        >
          <span>Change Password</span>
        </button>
      </div>
    </div>
  );
}

export default Password;
