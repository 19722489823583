import React, { Component } from "react";
import axios from "axios";
import Constants from "variables/Constants";
import { Button, Input } from "reactstrap";
import image from "../assets/img/cosmetic.avif";

import { IconButton, InputAdornment, TextField } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";

class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      message: "",
      redirect: false,
      warn: false,
      notification: false,
      error: false,
      loading: false,

      showPassword: false,
    };

    this.handleClickShowPassword = this.handleClickShowPassword.bind(this);
    this.handleMouseDownPassword = this.handleMouseDownPassword.bind(this);
  }
  handleClickShowPassword() {
    this.setState((prevState) => ({
      showPassword: !prevState.showPassword,
    }));
  }

  handleMouseDownPassword(event) {
    event.preventDefault();
  }

  handleSubmit = (e) => {
    this.setState({
      loading: true,
    });
    e.preventDefault();
    let payLoad = {
      email: this.state.username,
      password: this.state.password,
      type: "email",
      access: "dashboard",
    };
    axios.post(Constants.postUrls.login, payLoad).then((resp) => {
      console.log(resp);
      if (resp.data.status === "failed") {
        this.setState({
          loading: false,
          message: resp.data.message,
          error: true,
        });
        setTimeout(() => {
          this.setState({
            error: false,
          });
        }, 1000);
      } else {
        localStorage.setItem("accessToken", resp.data.token);
        localStorage.setItem("user", JSON.stringify(resp.data.user));
        localStorage.setItem(
          "usersPermission",
          JSON.stringify(resp.data.usersPermission)
        );
        setTimeout(() => {
          window.location.href = "/";
        }, 1000);
        this.setState({
          notification: true,
        });
      }
    });
  };
  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  render() {
    const { showPassword } = this.state;

    return (
      <div
        style={{
          fontFamily: "Roboto, Arial, sans-serif",
          position: "relative",
          backgroundImage: `url('${image}')`,
          backgroundRepeat: " no-repeat",
          backgroundPosition: "left",
          backgroundSize: "cover",
          height: "100vh",
          width: "100%",
        }}
      >
        <div
          style={{
            position: "absolute",
            height: "100%",
            width: "100%",
            backgroundColor: "#00000078",
          }}
        ></div>
        <div
          style={{
            position: "absolute",
            top: "50%",
            right: "35%",
            transform: "translateY(-50%)",
            width: "32%",
            backgroundColor: "#ffffff6b",
            padding: "35px",
            borderRadius: "13px",
          }}
        >
          <h4
            style={{
              fontWeight: "bold",
              fontSize: "24px",
              color: "#000",
            }}
          >
            Welcome, Admin!
          </h4>
          <p
            style={{
              fontWeight: "bold",
              color: "#000",
              fontSize: "16px",
              marginTop: "3px",
            }}
          >
            Please login to your account
          </p>
          <form onSubmit={this.handleSubmit}>
            <div style={{ marginTop: "32px", marginBottom: "24px" }}>
              <input
                // className="inputs input1"
                style={{
                  padding: "15px 56px 15px 20px",
                  width: "100%",
                  borderRadius: "5px",
                  // borderTopLeftRadius: "5px",
                  // borderTopRightRadius: "5px",
                  marginBottom: "10px",
                  border: "none",
                  backgroundColor: "#F0F8FF	",
                  boxShadow:
                    "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
                }}
                type="text"
                onChange={this.handleInput}
                required={true}
                name="username"
                placeholder="Username"
              />

              {/* <input
                type="password"
                // className="inputs input2"
                style={{
                  padding: "15px 56px 15px 20px",
                  width: "100%",
                  borderRadius: "5px",
                  // borderBottomLeftRadius: "5px",
                  // borderBottomRightRadius: "5px",
                  border: "none",
                  boxShadow:
                    "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
                }}
                onChange={this.handleInput}
                required={true}
                name="password"
                placeholder="Password"
              /> */}
            </div>
            <TextField
              name="password"
              style={{
                // padding: "25px 56px 15px 40px",
                // height:"70px",
                // borderRadius:"15px",
                // padding:"5px",
                // width: "100%",
                // // borderRadius: "5px"
                borderBottomLeftRadius: "5px",
                // // borderBottomRightRadius: "5px",
                // border: "none",
                boxShadow:
                  "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",

                // padding:"20px",
                padding: "0px 16px 5px 15px",
                // backgroundColor: "rgb(248, 248, 248)",
                backgroundColor: "rgb(230, 238, 245)	",
                border: "1px solid #ddd",
                borderRadius: "5px",
                boxShadow: "0px 0px 5px 0px #ddd",
                width: "100%",
                // padding: "3px",
                marginBottom: "10px",
              }}
              onChange={this.handleInput}
              required={true}
              label="Password"
              type={showPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={this.handleClickShowPassword}
                      onMouseDown={this.handleMouseDownPassword}
                      padding="12px"
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <br />
            <div className="d-flex justify-content-center">
              <Button color="primary" type="submit">
                {this.state.loading ? (
                  "Logging In..."
                ) : this.state.error ? (
                  <span style={{ color: "#BD0600" }}>
                    Incorrect Credentials!!!
                  </span>
                ) : (
                  "Login"
                )}
              </Button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default SignIn;
