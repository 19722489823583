import React, { Component } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
  Button,
  UncontrolledAlert,
  Pagination,
  PaginationItem,
  PaginationLink,
  FormGroup,
} from "reactstrap";
import axios from "axios";
import Constants from "../variables/Constants";
import { Link } from "react-router-dom";
import Modal from "react-awesome-modal";
import Input from "reactstrap/lib/Input";
import "../css/ToolTipStyle.css";
class Users extends Component {
  state = {
    showKYC: [],
    data: [],
    notifyStatus: false,
    notifyDelete: false,
    visible: false,
    searching: false,
    searched: false,
    delLoading: false,
    delLoading: false,
    trendId: 0,
    notifyTrending: false,
    visibleTrending: false,
    trending: false,
    rowId: 0,
    status: "",
    active: 1,
    perPage: "10",
    currentPage: 1,
    prevPage: 0,
    nextPage: 0,
    lastPage: 0,
    lastPageView: false,
    firstPageView: false,
    total: 0,
    from: 0,
    to: 0,
    fromInc: 1,
    toInc: 5,
    btnIdArray: [],
    filteredBtnIdArray: [],
    name: "",
  };

  componentDidMount() {
    this.getUsers();
  }
  getUsers = () => {
    axios
      .get(
        Constants.getUrls.user +
          "?limit=" +
          this.state.perPage +
          "&skip=0&page=" +
          this.state.active +
          "&q=" +
          this.state.name +
          "&is_delete=no" +
          "&sort=true"
      )
      .then((resp) => {
        console.log(resp, "all users");
        // return ===//check krne ke liye kiya jata h
        let btnIdArray = [];
        for (let i = 1; i <= resp.data.user.totalPages; i++) {
          btnIdArray.push(i);
        }
        this.setState(
          {
            fromInc: 1,
            active: 1,
            toInc: 5,
            btnIdArray,
            data: resp.data.user.docs,
            total: resp.data.totalDocs,
            from: 1,
            to: resp.data.user.docs.length,
          },
          () => {
            let filteredBtnIdArray = this.state.btnIdArray.filter(
              (v) => v >= this.state.fromInc && v <= this.state.toInc
            );
            this.setState({
              filteredBtnIdArray,
            });
          }
        );
      });
  };
  handlePage = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        fromInc: 1,
        active: 1,
        toInc: 5,
      },
      () => {
        axios
          .get(
            Constants.getUrls.user +
              "?limit=" +
              this.state.perPage +
              "&skip=0&page=" +
              this.state.active +
              "&is_delete=no"
          )
          .then((resp) => {
            //console.log(resp.data);
            let btnIdArray = [];
            for (let i = 1; i <= resp.data.user.totalPages; i++) {
              btnIdArray.push(i);
            }
            this.setState(
              {
                btnIdArray,
                data: resp.data.user.docs,
                total: resp.data.user.totalDocs,
                from: 1,
                to: resp.data.user.docs.length,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({
                  filteredBtnIdArray,
                });
              }
            );
          });
      }
    );
  };
  currentPage = (v) => {
    let commonUrl =
      Constants.getUrls.user +
      "?limit=" +
      this.state.perPage +
      "&is_delete=no" +
      "&sort=true" +
      "&skip=0&page=";
    axios.get(commonUrl + v).then((resp) => {
      // console.log(resp.data);
      this.setState({
        data: resp.data.user.docs,
        total: resp.data.user.totalDocs,
        from: v * this.state.perPage - (this.state.perPage - 1),
        to:
          v * this.state.perPage > this.state.total
            ? this.state.total
            : v * this.state.perPage,
        active: v,
      });
    });
  };
  // currentPage = (v) => {
  //   let commonUrl =
  //     Constants.getUrls.user
  //     +
  //     "?limit=" +
  //     this.state.perPage +
  //     "&skip=0&page="+ "&is_delete=no";
  //   axios.get(commonUrl + v).then((resp) => {
  //     console.log(resp);
  //     this.setState({
  //       data: resp.data.user.docs,
  //       total: resp.data.user.totalDocs,
  //       from: v * this.state.perPage - (this.state.perPage - 1),
  //       to:
  //         v * this.state.perPage > this.state.total
  //           ? this.state.total
  //           : v * this.state.perPage,
  //       active: v,
  //     });
  //   });
  // };

  prevPages = () => {
    this.setState(
      {
        fromInc: this.state.fromInc - 5,
        toInc: this.state.toInc - 5,
      },
      () => {
        let filteredBtnIdArray = this.state.btnIdArray.filter(
          (v) => v >= this.state.fromInc && v <= this.state.toInc
        );
        if (filteredBtnIdArray.length >= 1) {
          if (filteredBtnIdArray.length === 1) {
            this.setState(
              {
                fromInc: this.state.fromInc + 4,
                toInc: this.state.toInc + 4,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 2) {
            this.setState(
              {
                fromInc: this.state.fromInc + 3,
                toInc: this.state.toInc + 3,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 3) {
            this.setState(
              {
                fromInc: this.state.fromInc + 2,
                toInc: this.state.toInc + 2,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 4) {
            this.setState(
              {
                fromInc: this.state.fromInc + 1,
                toInc: this.state.toInc + 1,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else {
            this.setState({ filteredBtnIdArray });
          }
        } else {
          this.setState(
            {
              fromInc: this.state.fromInc + 5,
              toInc: this.state.toInc + 5,
            },
            () => {
              let filteredBtnIdArray = this.state.btnIdArray.filter(
                (v) => v >= this.state.fromInc && v <= this.state.toInc
              );
              this.setState({ filteredBtnIdArray });
            }
          );
        }
      }
    );
  };

  nextPages = () => {
    this.setState(
      {
        fromInc: this.state.fromInc + 5,
        toInc: this.state.toInc + 5,
      },
      () => {
        let filteredBtnIdArray = this.state.btnIdArray.filter(
          (v) => v >= this.state.fromInc && v <= this.state.toInc
        );
        if (filteredBtnIdArray.length >= 1) {
          if (filteredBtnIdArray.length === 1) {
            this.setState(
              {
                fromInc: this.state.fromInc - 4,
                toInc: this.state.toInc - 4,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 2) {
            this.setState(
              {
                fromInc: this.state.fromInc - 3,
                toInc: this.state.toInc - 3,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 3) {
            this.setState(
              {
                fromInc: this.state.fromInc - 2,
                toInc: this.state.toInc - 2,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 4) {
            this.setState(
              {
                fromInc: this.state.fromInc - 1,
                toInc: this.state.toInc - 1,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else {
            this.setState({ filteredBtnIdArray });
          }
        } else {
          this.setState(
            {
              fromInc: this.state.fromInc - 5,
              toInc: this.state.toInc - 5,
            },
            () => {
              let filteredBtnIdArray = this.state.btnIdArray.filter(
                (v) => v >= this.state.fromInc && v <= this.state.toInc
              );
              this.setState({ filteredBtnIdArray });
            }
          );
        }
      }
    );
  };
  handleStatus = (e, id) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        const payload = {
          status: this.state.status,
        };
        axios
          .post(Constants.statusUrl + id + "?type=coupon_code", payload)
          .then(() => {
            this.setState({
              notifyStatus: true,
            });
            setTimeout(() => {
              this.setState({ notifyStatus: false }, () => {
                this.getUsers();
              });
            }, 1000);
          });
      }
    );
  };
  handleDelete = (id) => {
    this.setState({ delLoading: true });
    axios.delete(Constants.getUrls.user + "/" + id).then(() => {
      this.setState({
        notifyDelete: true,
        delLoading: false,
      });
      setTimeout(() => {
        this.setState({ notifyDelete: false, visible: false }, () => {
          this.getUsers();
        });
      }, 2000);
    });
  };

  handleModal = (userData) => {
    // console.log(userData?._id)
    axios
      .get(Constants.getUrls.showUserKYC + "/" + userData?._id)
      .then((resp) => {
        // console.log(resp , "romende")
        this.setState({
          showKYC: resp?.data?.kyc,
        });
      });

    this.setState({
      // rowId: sec._id,
      visible: true,
    });
  };

  handleDownload = (name, email, mobile_no, dob) => {
    // console.log(name,email, mobile_no,dob)
    let payload = {
      columns: ["name", "email", "mobile_no", "dob"],
    };
    axios
      .post(Constants.postUrls.downloadCsv, payload, {
        headers: {
          Authorization:
            "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NDI0NDFlYjFlNDZiOTNkYzFhNDcxODQiLCJpYXQiOjE2ODEyNzkzNjB9.4J-fKMcFuuYKIOVLcqKiKzHNxjG8EFTmtDLdhdv-pIU",
        },
      })
      .then((res) => {
        console.log("@@@", res.data?.url);
        if (res.data) {
          if (res.data.url) {
            let baseurl = Constants.imgUrl + res.data.url;
            window.open(baseurl, "_blank");
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  productSearch = () => {
    this.setState({ searching: true });
    axios
      .post(
        Constants.postUrls.user +
          "?limit=" +
          this.state.perPage +
          "&skip=0&page=1",
        { name: this.state.name }
      )
      .then((resp) => {
        // console.log(resp, "rom");
        let btnIdArray = [];
        for (let i = 1; i <= resp.data.user.pageCount; i++) {
          btnIdArray.push(i);
        }

        this.setState(
          {
            btnIdArray,
            data: resp.data.user.docs,
            total: resp.data.user.itemCount,
            from: 1,
            fromInc: 1,
            active: 1,
            toInc: 5,
            searched: true,
            searching: false,
            to: resp.data.user.docs.length,
          },
          () => {
            let filteredBtnIdArray = this.state.btnIdArray.filter(
              (v) => v >= this.state.fromInc && v <= this.state.toInc
            );
            this.setState({
              filteredBtnIdArray,
            });
          }
        );
      });
  };

  render() {
    return (
      <>
        <div className="content">
          {this.state.notifyStatus ? (
            <div
              style={{
                width: "250px",
                position: "relative",
                left: "40%",
                top: "7px",
              }}
            >
              <UncontrolledAlert color="success" fade={false}>
                <span className="alert-inner--icon">
                  <i className="ni ni-like-2" />
                </span>{" "}
                <span className="alert-inner--text">
                  <strong>Status Changed!</strong>
                </span>
              </UncontrolledAlert>
            </div>
          ) : null}
          <Row>
            <Col md="12">
              <Card style={{ position: "relative" }}>
                <FormGroup
                  style={{
                    position: "absolute",
                    top: "26px",
                    right: "2%",
                    width: "85px",
                  }}
                >
                  <label>Per Page</label>
                  <br />
                  <select
                    className="form-control"
                    name="perPage"
                    value={this.state.perPage}
                    onChange={this.handlePage}
                  >
                    {[10, 25, 50].map((category, i) => (
                      <option key={i} value={category}>
                        {category}
                      </option>
                    ))}
                  </select>
                </FormGroup>

                <CardHeader>
                  <CardTitle tag="h4">Users Table</CardTitle>
                  <Col md="3">
                    {/* <FormGroup>
                      <Input
                        placeholder="Name"
                        name="name"
                        value={this.state.name}
                        onChange={(e) => {
                          this.setState({ name: e.target.value }, () => {
                            this.getUsers();
                          });
                        }}
                        type="text"
                      />
                    </FormGroup> */}
                    <div className="d-flex">
                      <FormGroup>
                        <Input
                          placeholder="Name"
                          name="name"
                          value={this.state.name}
                          onChange={(e) => {
                            this.setState({ name: e.target.value }, () => {
                              this.getUsers();
                            });
                          }}
                          type="text"
                        />
                      </FormGroup>
                      <Button
                        style={{
                          position: "absolute",
                          left: "210px",
                          top: "2px",
                        }}
                        className="btn-round"
                        color="primary"
                        onClick={() => {
                          this.handleDownload();
                        }}
                      >
                        Export To CSV
                      </Button>
                    </div>

                    {/* <Button
                      style={{
                        position: "absolute",
                        right: "-80px",
                        top: "0px",
                      }}
                      className="btn-round"
                      color="success"
                      type="submit"
                      onClick={this.productSearch}
                    >
                      {this.state.searching ? "Working" : "Search"}
                    </Button> */}
                  </Col>
                </CardHeader>
                <CardBody>
                  <Table responsive>
                    <thead className="text-primary">
                      <tr>
                        <th>Sr.No</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Mobile No.</th>
                        {/* <th>DOB</th> */}
                        {/* <th>BANK DETAILS</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.data.map((v, i) => (
                        <tr key={i}>
                          <td>
                            <span>{i + 1}</span>
                          </td>
                          <td>
                            <span>{v.name}</span>
                          </td>
                          <td>
                            <span>{v.email}</span>
                          </td>
                          <td>
                            <span>{v.mobile_no}</span>
                          </td>
                          {/* <td>
                            <span>{new Date(v.dob).toDateString()}</span>
                          </td> */}

                          {/* <td>
                            <Button
                              className="btn-round"
                              color="success"
                              type="submit"
                              style={{
                                marginLeft: "10px",
                                width: "65px",
                                height: "45px",
                              }}
                              onClick={() => {
                                this.handleModal(v);
                                <span>{v.kyc ? v.kyc : "NA"}</span>;
                              }}
                            >
                              View KYC
                              <div className="mongo">
                                <span class="material-symbols-outlined">
                                  account_balance
                                </span>
                                <span className="mongo-title">View KYC</span>
                              </div>
                            </Button>
                          </td> */}
                        </tr>
                      ))}
                    </tbody>
                    <Modal
                      visible={this.state.visible}
                      width="400"
                      height="320"
                      effect="fadeInUp"
                      onClickAway={() => this.setState({ visible: false })}
                    >
                      {this.state.showKYC &&
                      Object.keys(this.state.showKYC).length > 0 ? (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginTop: "50px",
                          }}
                        >
                          <span>
                            BANK DETAILS
                            <table>
                              <tr>
                                <td>Account Holder Name</td>
                                <td>
                                  {this.state.showKYC?.account_holder_name}
                                </td>
                              </tr>
                              <tr>
                                <td>Account Number</td>
                                <td> {this.state.showKYC?.account_number}</td>
                              </tr>
                              <tr>
                                <td>Branch Name</td>
                                <td>{this.state.showKYC?.branch_name}</td>
                              </tr>
                              <tr>
                                <td>Branch IFSC CODE</td>
                                <td>{this.state.showKYC?.ifsc_code}</td>
                              </tr>

                              {/* Account Holder Name:   {this.state.showKYC?.account_holder_name}
                       <br/>
                       Account Number:    {this.state.showKYC?.account_number}
                      <br/>
                      Branch Name:   {this.state.showKYC?.branch_name}
                      <br/>
                        Branch IFSC CODE:   {this.state.showKYC?.ifsc_code} */}
                            </table>
                          </span>
                        </div>
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginTop: "150px",
                          }}
                        >
                          <span>No KYC FOUND</span>
                        </div>
                      )}

                      {/* <Row>
                        <Col xs={2} sm={12} md={12}>
                          <Card>
                           
                            <CardBody>
                              <div>
                                <h3>Are You Sure You Want to Delete It?</h3>
                                {this.state.notifyDelete ? (
                                  <div>
                                    <span style={{ color: "green" }}>
                                      Coupon Deleted Successfuly!
                                    </span>
                                  </div>
                                ) : null}
                                <Row>
                                  <Col xs={12} sm={12} md={4}>
                                    <br />
                                    <Button
                                      className=""
                                      color="warning"
                                      onClick={() => {
                                        this.handleDelete(this.state.rowId);
                                      }}
                                    >
                                      {this.state.delLoading
                                        ? "Deleting..."
                                        : "Yes"}
                                    </Button>
                                    <Button
                                      className=""
                                      color="success"
                                      onClick={() => {
                                        this.setState({ visible: false });
                                      }}
                                    >
                                      No
                                    </Button>
                                  </Col>
                                </Row>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row> */}
                    </Modal>
                  </Table>
                  {this.state.data.length === 0 ? (
                    <div>
                      <h6
                        style={{
                          textAlign: "center",
                          marginTop: "30px",
                          fontSize: "14px",
                        }}
                      >
                        No Data Found!
                      </h6>
                    </div>
                  ) : (
                    <div style={{ marginTop: "20px" }}>
                      <div style={{ float: "left" }}>
                        Showing {this.state.from} to {this.state.to} of{" "}
                        {this.state.total} entries.
                      </div>

                      <div style={{ float: "right" }}>
                        <Pagination>
                          <PaginationItem>
                            <PaginationLink
                              onClick={this.prevPages}
                              tabIndex="-1"
                            >
                              <i className="fa fa-angle-left" />
                              <span className="sr-only">Previous</span>
                            </PaginationLink>
                          </PaginationItem>
                          {this.state.filteredBtnIdArray.map((v, i) => (
                            <PaginationItem
                              className={
                                this.state.active === v ? "active" : ""
                              }
                              key={i}
                            >
                              <PaginationLink
                                onClick={() => {
                                  this.currentPage(v);
                                }}
                              >
                                {v}
                              </PaginationLink>
                            </PaginationItem>
                          ))}
                          <PaginationItem>
                            <PaginationLink onClick={this.nextPages}>
                              <i className="fa fa-angle-right" />
                              <span className="sr-only">Next</span>
                            </PaginationLink>
                          </PaginationItem>
                        </Pagination>
                      </div>
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default Users;
