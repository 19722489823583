import { faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
// import { Button } from "bootstrap";
import NotificationOrganelle from "components/Organelles/NotificationOrganelle";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { Card, CardHeader, CardBody, CardTitle, Table } from "reactstrap";
import { useParams } from "react-router-dom";

import Constants from "variables/Constants";
import routes from "routes";
import zIndex from "@material-ui/core/styles/zIndex";
import { Select } from "@material-ui/core";
function SubAdmin() {
  const [updatePopUp, setUpdatePopUp] = useState(false);
  const [deletePopUp, setDeletePopUp] = useState(false);
  const [updateNotification, setUpdateNotification] = useState(false);
  const [deleteNotification, setDeleteNotification] = useState(false);
  const [title, setTitle] = useState("");
  const [data, setData] = useState([]);
  const [id, setId] = useState("");
  const [show, setShow] = useState(false);
  const [subAdminStatus, setSubAdminStatus] = useState("active");

  useEffect(() => {
    fetch_data();
  }, []);

  useEffect(() => {
    console.log(id);
  }, [id]);

  const fetch_data = () => {
    console.log(Constants.getUrls.subAdmin);
    axios.get(`${Constants.getUrls.subAdmin}?is_delete=no`).then((response) => {
      console.log(response.data.permission.docs, "all sub admins");
      setData(response.data.permission.docs);
    });
  };

  const change_status = () => {
    const payload = { status: subAdminStatus };
    axios
      .post(`${Constants.postUrls.statusUpdate}/${id}type=submenu`)
      .then((response) => {
        console.log(response, "status change");
      });
  };

  const delete_data = () => {
    axios.delete(`${Constants.deleteUrls.subAdmin}${id}`).then((response) => {
      console.log(response, "delete response");
      if (response.data.status === "success") {
        fetch_data();
        setDeletePopUp(false);
        setDeleteNotification(true);
        setTimeout(() => {
          setDeleteNotification(false);
        }, 3000);
      } else {
        alert("Item is not deleted");
      }
    });
  };

  return (
    <>
      {deleteNotification && (
        <NotificationOrganelle
          style={{
            backgroundColor: "#219d68",
            position: "fixed",
            color: "white",
            right: "10px",
            top: "10px",
            zIndex: "999",
          }}
          message={
            <>
              <span style={{ textTransform: "uppercase", fontWeight: "bold" }}>
                {title}
              </span>
              , Deleted Successfully!
            </>
          }
        />
      )}
      {updateNotification && (
        <NotificationOrganelle
          style={{
            backgroundColor: "#219d68",
            position: "fixed",
            color: "white",
            right: "10px",
            top: "10px",
          }}
          message={
            <>
              Menu Updated to{" "}
              <span style={{ textTransform: "uppercase" }}>{title}</span>,
              Successfully!
            </>
          }
        />
      )}

      {deletePopUp && (
        <>
          <div
            style={{
              position: "fixed",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              justifyContent: "center",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              height: "200px",
              width: "500px",
              boxShadow: "1px 1px 10px 10px #8080801c",
              backgroundColor: "white",
              zIndex: "10",
            }}
          >
            <p>
              Are You Sure, You Want to Delete{" "}
              <span
                style={{ fontWeight: "bolder", textTransform: "uppercase" }}
              >
                {" "}
                {title}{" "}
              </span>{" "}
              ?
            </p>
            <div className="d-flex align-items-center justify-content-center">
              <button
                className="mr-3 btn btn-danger"
                onClick={() => {
                  delete_data();

                  // delete_menu();
                }}
              >
                Delete
              </button>
              <button
                className="btn btn-success"
                onClick={() => {
                  setDeletePopUp(false);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
          <div
            style={{
              position: "fixed",
              top: "0",
              bottom: "0",
              left: "0",
              right: "0",
              backgroundColor: "#0000002e",
              zIndex: "9",
            }}
            onClick={() => {
              setDeletePopUp(false);
            }}
          ></div>
        </>
      )}
      <Card>
        <CardHeader>
          <CardTitle>SubAdmin</CardTitle>
        </CardHeader>
        <Link to={"/admin/addSubAdmin"}>
          <button
            onClick={() => {}}
            style={{ width: "fit-content" }}
            className="btn btn-success ml-3"
          >
            Add Sub Admin
          </button>
        </Link>
        <CardBody>
          <Table>
            <thead>
              <th>Name</th>
              <th>Mobile</th>
              <th>Email</th>
              {/* <th>Alloted Menus Id</th> */}
              <th className="text-center"> Status</th>
              <th>Actions</th>
            </thead>
            {data.map((subAdmin) => (
              <tbody>
                <td>{subAdmin.user.name}</td>
                <td>{subAdmin.user.mobile_no}</td>
                <td>{subAdmin.user.email}</td>
                {/* <td className="d-flex flex-column position-relative">
                  <span
                    style={{
                      color: "blue",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setShow(!show);
                      setId(subAdmin.user._id);
                    }}
                  >
                    Show
                  </span>
                  {show && subAdmin.user._id === id ? (
                    <div
                      className="scroll-bar-div"
                      style={{
                        position: "absolute",
                        left: "75px",
                        width: "100px",
                        padding: "5px",
                        backgroundColor: "white",
                        boxShadow: "1px 1px 2px 2px #0000001f",
                        zIndex: "99",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      {routes.map((item) =>
                        subAdmin.menus.map((menu) =>
                          item.id === menu ? (
                            <span style={{ width: "fit-content" }}>
                              {item.name}
                            </span>
                          ) : null
                        )
                      )}
                    </div>
                  ) : null}
                </td> */}
                <td>
                  <select
                    className="form-control text-center"
                    value={subAdmin.status}
                  >
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                  </select>
                </td>
                <td>
                  <Link
                    onClick={() => {
                      console.log(subAdmin.user._id, "user id");
                    }}
                    to={`/admin/updatesubAdmin/${subAdmin.user._id}`}
                    className="mr-2"
                  >
                    <FontAwesomeIcon
                      className="font-awesome-icons pr-2 fa-update"
                      icon={faPen}
                    />
                  </Link>
                  {/* ///// delete ////// */}
                  <Link
                    className="mr-2"
                    onClick={() => {
                      setDeletePopUp(true);
                    }}
                  >
                    <FontAwesomeIcon
                      onClick={() => {
                        setTitle(subAdmin.user.name);
                        setId(subAdmin.user._id);
                        setDeletePopUp(true);
                      }}
                      className="font-awesome-icons pr-2 fa-trash"
                      icon={faTrash}
                    />
                  </Link>
                </td>
              </tbody>
            ))}
          </Table>
        </CardBody>
      </Card>
    </>
  );
}

export default SubAdmin;
