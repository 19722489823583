import React, { Component } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Button,
  FormGroup,
  Form,
  Input,
  UncontrolledAlert,
} from "reactstrap";
import axios from "axios";
import Constants from "../variables/Constants";
import { Redirect, Link } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import MultiSelect from "react-multi-select-component";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

//Added By Nehal Prasad on 29/-03/2023
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { Grid, Box, Modal } from "@material-ui/core";
import SelectOrganelle from "components/Organelles/SelectOrganelle";

class AddProduct extends Component {
  state = {
    name: "",

    categories: [],
    productsFor: [],
    productFor: "",
    category: "",
    productIds: [],
    products: [],

    subCategories: [],

    subCategory: "",
    overview: "",
    note: "",
    charge: "",
    specification: "",
    taxType: "",
    taxTypes: [],
    brands: [],
    brands_id: [],
    sub_categories: [],
    superCategoryValue: {
      value: "",
      label: "",
    },
    categoryValue: {
      value: "",
      label: "",
    },
    subCategoryValue: {
      value: "",
      label: "",
    },
    brandValue: {
      value: "",
      label: "",
    },
    type_value: "",
    // value: "",

    product_exchange: "",

    image: "",
    video: [],

    short_description: "",

    warn: false,
    redirect: false,
    warning: false,
    notification: false,
    loading: false,
    super_categories: [],
    super_category: "",
    product_return: "",

    // Added by Nehal Prasad on 29/03/2023

    pics: [],
    selectedFiles: [],

    myArray: [],
    myArrayLength: "",

    selectedImage: "",
    currenIndex: 0,

    imageCrop: {
      unit: "%",
      x: "",
      y: "",
      width: 60,
      height: 70,
    },

    croppedImage: [],
    firstpic: "",
    secondpic: "",
    thirdpic: "",
    fourthpic: "",
    fifthpic: "",
    modalVisible: "false",
    finalImage: [],
    tribeValue: "",
    product_id: "",
  };
  componentDidMount() {
    this.fetchSuperCategories();
    this.getBrands();

    axios
      .get(
        Constants.getUrls.productsFor +
          "/" +
          this.state.category +
          "?limit=50&skip=1&page=1" +
          "&is_delete=no"
      )
      .then((resp) => {
        console.log(resp, "productsFor");
        this.setState({
          productsFor: resp.data.docs,
        });
      });

    // Tax Types API
    axios
      .get(
        Constants.getUrls.taxTypes + "?limit=50&skip=0&page=0" + "&is_delete=no"
      )
      .then((resp) => {
        // console.log(resp.data);
        this.setState({
          taxTypes: resp.data.docs,
        });
      });
    // Delivery Modes API
    // removed axios.get() of deliveryModes
  }

  handleOverlaySupCat = () => {
    this.setState({ superCategoryValue: { value: "", label: "" } });
  };
  handleOverlayCat = () => {
    this.setState({ categoryValue: { value: "", label: "" } });
  };
  handleOverlaySubCat = () => {
    this.setState({ subCategoryValue: { value: "", label: "" } });
  };
  handleOverlayBrand = () => {
    this.setState({ brandValue: { value: "", label: "" } });
  };

  fetchSuperCategories = (searchText = "") => {
    axios
      .get(
        `${Constants.getUrls.super_categories}?is_delete=no&status=ACTIVE&q=${searchText}`
      )
      .then((response) => {
        console.log(response.data.superCat.docs, "supercategories");
        this.setState({
          super_categories: response.data.superCat.docs,
        });
      });
  };

  // to get Category Id and name
  fetchCategories = (searchText = "") => {
    axios
      .get(
        Constants.getUrls.categories +
          "?super_category=" +
          this.state.superCategoryValue.value +
          "&q=" +
          searchText
      )
      .then((response) => {
        console.log(response.data.docs);
        this.setState({
          categories: response.data.docs,
        });
      });
  };
  // to get sub categories id and name
  fetchSubCategories = (searchText = "") => {
    axios
      .get(
        Constants.getUrls.subCategories +
          "?category=" +
          this.state.categoryValue.value +
          "&q=" +
          searchText
      )
      .then((response) => {
        console.log(response.data.docs, "subcat");
        this.setState({
          sub_categories: response.data.docs,
        });
      });
  };
  getBrands = (e = "") => {
    this.setState({ type_value: e }, () => {
      axios
        .get(
          `${Constants.getUrls.brand}?is_delete=no&status=ACTIVE&q=${this.state.type_value}`
        )
        .then((response) => {
          console.log(response.data.getBrand.docs, "brand");
          this.setState({ brands: response.data.getBrand.docs });
        });
    });
  };
  handleInput = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        // console.log(this.state.short_description);
      }
    );
  };
  handleSubCategory = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        axios
          .get(
            Constants.getUrls.subCategories +
              "?limit=50&page=1&status=ACTIVE" +
              "&category=" +
              this.state.category +
              `&super_category=` +
              this.state.super_category +
              "&is_delete=no"
          )
          .then((resp) => {
            console.log(resp);
            this.setState({
              subCategories: resp.data.docs,
            });
          });
      }
    );
  };

  handleBrand = () => {
    axios
      .get(`${Constants.getUrls.brand}?is_delete=no&limit=1000`)
      .then((response) => {
        console.log(response.data.getBrand.docs);
        this.setState({ brands: response.data.getBrand.docs });
      });
  };
  handleBrandId = (e) => {
    this.setState(
      {
        brands_id: e.target.value,
      },
      () => {}
    );
  };
  handleProductFor = (e = "") => {
    this.setState({ tribeValue: e }, () => {
      //  Products For API
      axios
        .get(
          Constants.getUrls.productsFor +
            "/" +
            this.state.subCategoryValue?.value +
            "?limit=50&skip=1&page=1" +
            "&is_delete=no&q=" +
            this.state.tribeValue
        )
        .then((resp) => {
          // console.log(resp);
          this.setState({
            productsFor: resp.data.docs,
          });
        });
    });
  };
  // handleFile = (e) => {
  //   this.setState({ [e.target.name]: e.target.files });
  // };

  // handleFile = (e) => {
  //   // console.log(e.target.files);
  //   this.setState({ [e.target.name]: e.target.files });
  //   // console.log(this.state.image);
  // };
  // removed handle video

  handleSubmit = async (e) => {
    e.preventDefault();
    // if (this.state.productIds.length===0) {
    //   this.setState({warn:true});
    // } else {
    this.setState({
      loading: true,
    });
    let prodIds = [];
    console.log(this.state.productIds);
    this.state.productIds.forEach((v) => {
      prodIds.push(v.value);
    });
    let fd = new FormData();
    if (this.state.productFor.length > 0) {
      fd.append("product_for", this.state.productFor);
    }
    // for (let i = 0; i < this.state.image.length; i++) {
    //   fd.append("image", this.state.image[i]);
    // }

    fd.append("name", this.state.name);
    fd.append("superCatId", this.state.superCategoryValue.value);
    fd.append("catId", this.state.categoryValue.value);
    if (this.state.subCategoryValue?.value.length > 0) {
      fd.append("subcatId", this.state.subCategoryValue.value);
    }
    if (this.state.brandValue?.value.length > 0) {
      fd.append("brand", this.state.brandValue.value);
    }

    if (this.state.charge.length > 0) {
      fd.append("delivery_amount", this.state.charge);
    }
    if (this.state.specification.length > 0) {
      fd.append("specification", this.state.specification);
    }
    if (this.state.note.length > 0) {
      fd.append("note", this.state.note);
    }
    if (this.state.overview.length > 0) {
      fd.append("product_overview", this.state.overview);
    }
    fd.append("tax_type", this.state.taxType);
    // fd.append("price", this.state.price);
    // if (this.state.offerType !== "") {
    //   fd.append("offer_type", this.state.offerType);
    // }
    //
    // fd.append("printIds", JSON.stringify(prodIds));

    fd.append("product_return", this.state.product_return);

    fd.append("product_exchange", this.state.product_exchange);

    // let payLoad = {
    //   // super_category: this.state.super_category,
    //   // category: this.state.category,
    //   // title: this.state.name,
    //   short_description: this.state.short_description
    // };

    if (this.state.short_description.length > 0) {
      fd.append("short_description", this.state.short_description);
    }
    // fd.append("image", this.state.image);

    // if (this.state.image) {
    //   for (let i = 0; i < this.state.image.length; i++) {
    //     fd.append("image", this.state.image[i]);
    //   }
    // }
    // }

    axios.post(Constants.getUrls.products, fd).then((resp) => {
      console.log(resp.data.data._id, "post response");
      if (resp.data.status === "success") {
        setTimeout(() => {
          this.setState({
            redirect: true,
            product_id: resp.data.data._id,
          });
        }, 1000);
        this.setState({
          notification: true,
          loading: false,
        });
      } else {
        this.setState({
          warning: true,
          loading: false,
        });
        setTimeout(() => {
          this.setState({
            warning: false,
          });
        }, 1000);
      }
    });
    // };
  };

  handleFinish = (e) => {
    this.setState({
      modalVisible: false,
    });

    const { croppedImage, firstpic, secondpic, thirdpic, fourthpic, fifthpic } =
      this.state;

    if (firstpic) {
      croppedImage.push(firstpic);
      console.log(firstpic);
    }
    if (secondpic) {
      croppedImage.push(secondpic);
      console.log(secondpic);
      console.log(typeof secondpic);
    }
    if (thirdpic) {
      croppedImage.push(thirdpic);
    }
    if (fourthpic) {
      croppedImage.push(fourthpic);
    }
    if (fifthpic) {
      croppedImage.push(fifthpic);
    }
  };

  refForImages = (pics) => {
    pics.map((key, index) => {
      const obj = URL.createObjectURL(key);
      console.log(obj);
      this.state.myArray.push(obj);
    });

    console.log(this.state.myArray);
    const text = this.state.myArray;
    console.log(text.length);
    this.setState({
      myArrayLength: text.length,
    });
  };

  onImageLoaded = (image) => {
    // console.log(image)
    this.imageRef = image;
  };

  onImageCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    // You could also use percentCrop:
    // console.log(crop)
    // this.setState({ crop: percentCrop });
    this.setState({ imageCrop: crop });
    // console.log(this.state.imageCrop)
  };

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    const pixelRatio = window.devicePixelRatio;
    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          console.error("Canvas is empty");
          return;
        }
        blob.name = fileName;
        // window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(this.fileUrl);
      }, "image/jpeg");
    });
  }

  async makeClientCrop(crop) {
    // console.log(crop)
    // console.log(this.imageRef)
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        "newFile.jpeg"
      );

      let obj = [];
      obj.push(croppedImageUrl);

      if (this.state.currenIndex === 0) {
        this.setState({
          firstpic: obj.splice(-1),
        });
      }

      if (this.state.currenIndex === 1) {
        this.setState({
          secondpic: obj.splice(-1),
        });
      }

      if (this.state.currenIndex === 2) {
        this.setState({
          thirdpic: obj.splice(-1),
        });
      }
      if (this.state.currenIndex === 3) {
        this.setState({
          fourthpic: obj.splice(-1),
        });
      }
      if (this.state.currenIndex === 4) {
        this.setState({
          fifthpic: obj.splice(-1),
        });
      }
    }
  }

  handleImage = (e) => {
    console.log(e.target.files);
    const selectedFiles = Array.from(e.target.files);
    console.log(selectedFiles);

    if (selectedFiles.length > 0) {
      this.setState({
        modalVisible: true,
      });
    }
    e.persist();
    if (e.target.files[0]) {
      this.setState(
        {
          [e.target.name]: e.target.files[0],
          pics: e.target.files,
          selectedFiles: selectedFiles,
          currentIndex: 0,
        },
        () => {
          this.refForImages(selectedFiles);
        }
      );
    }
  };

  handleNext = () => {
    this.setState({
      currenIndex: (this.state.currenIndex + 1) % this.state.myArrayLength,
      imageCrop: {
        unit: "%",
        x: "",
        y: "",
        width: 60,
        height: 70,
      },
    });
  };

  handlePrev = () => {
    this.setState({
      currenIndex: (this.state.currenIndex - 1) % this.state.myArrayLength,
      imageCrop: {
        unit: "%",
        x: "",
        y: "",
        width: 60,
        height: 70,
      },
    });
  };
  selectBrand = (brand) => {
    this.setState({ brandValue: brand }, () => {
      console.log(this.state.brandValue, "brandValue");
    });
  };
  selectSuperCat = (supercat) => {
    console.log(supercat);
    this.setState(
      {
        superCategoryValue: supercat,
        categoryValue: {
          label: "",
          value: "",
        },
        subCategoryValue: {
          label: "",
          value: "",
        },
      },
      () => {
        console.log(this.state.superCategoryValue, "supervalue");
        // ;
        this.fetchCategories();
      }
    );
  };
  selectCat = (cat) => {
    this.setState(
      {
        categoryValue: cat,
        subCategoryValue: {
          label: "",
          value: "",
        },
      },
      () => {
        this.fetchSubCategories();
      }
    );
  };
  selectSubCat = (subcat) => {
    this.setState(
      {
        subCategoryValue: subcat,
      },
      () => {}
    );
  };

  render() {
    const {
      myArray,
      myArrayLength,
      modalVisible,
      currenIndex,
      firstpic,
      secondpic,
      thirdpic,
      fourthpic,
      fifthpic,
    } = this.state;
    if (this.state.redirect) {
      return <Redirect to={`/admin/variants/${this.state.product_id}`} />;
    } else {
      return (
        <>
          <div className="content">
            {this.state.notification ? (
              <div
                style={{
                  width: "250px",
                  position: "relative",
                  left: "40%",
                  top: "7px",
                }}
              >
                <UncontrolledAlert color="success" fade={false}>
                  <span className="alert-inner--icon">
                    <i className="ni ni-like-2" />
                  </span>{" "}
                  <span className="alert-inner--text">
                    <strong>Product Added!</strong>
                  </span>
                </UncontrolledAlert>
              </div>
            ) : null}
            {this.state.warning ? (
              <div
                style={{
                  width: "250px",
                  position: "relative",
                  left: "40%",
                  top: "7px",
                }}
              >
                <UncontrolledAlert color="danger" fade={false}>
                  <span className="alert-inner--icon">
                    <i className="ni ni-dislike-2" />
                  </span>{" "}
                  <span className="alert-inner--text">
                    <strong>Some Error Occured!</strong>
                    {/* <strong>Some Error Occured!</strong> */}
                  </span>
                </UncontrolledAlert>
              </div>
            ) : null}
            <Row>
              <Col md="12">
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">Add Product</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Form onSubmit={this.handleSubmit}>
                      <Row>
                        <Col className="pr-1" md="3">
                          <SelectOrganelle
                            handle_overlay={() => {
                              this.handleOverlaySupCat();
                            }}
                            label={"Select Super Category"}
                            value={this.state.superCategoryValue}
                            onChange={(searchText) => {
                              console.log(searchText);
                              this.setState(
                                {
                                  superCategoryValue: {
                                    value: "",
                                    label: searchText,
                                  },
                                },
                                () => {
                                  this.fetchSuperCategories(searchText);
                                }
                              );
                            }}
                            options={this.state.super_categories.map(
                              (option) => {
                                return {
                                  label: option.title,
                                  value: option._id,
                                };
                              }
                            )}
                            onSelect={this.selectSuperCat}
                          />
                        </Col>
                        <Col className="pr-1" md="3">
                          <SelectOrganelle
                            handle_overlay={() => {
                              this.handleOverlayCat();
                            }}
                            label={"Category"}
                            value={this.state.categoryValue}
                            options={this.state.categories.map((option) => {
                              return {
                                label: option.name,
                                value: option._id,
                              };
                            })}
                            onChange={(searchText) => {
                              console.log(searchText);
                              this.setState(
                                {
                                  categoryValue: {
                                    value: "",
                                    label: searchText,
                                  },
                                },
                                () => {
                                  this.fetchCategories(searchText);
                                }
                              );
                            }}
                            onSelect={this.selectCat}
                          />
                        </Col>
                        <Col className="pr-1" md="3">
                          <SelectOrganelle
                            handle_overlay={() => {
                              this.handleOverlaySubCat();
                            }}
                            label={"Sub Category"}
                            value={this.state.subCategoryValue}
                            options={this.state.sub_categories.map((option) => {
                              return {
                                label: option.name,
                                value: option._id,
                              };
                            })}
                            onChange={(searchText) => {
                              console.log(searchText);
                              this.setState(
                                {
                                  subCategoryValue: {
                                    value: "",
                                    label: searchText,
                                  },
                                },
                                () => {
                                  this.fetchSubCategories(searchText);
                                }
                              );
                              // this.setState(
                              //   {
                              //     subcatSearch: searchText,
                              //   },
                              //   this.fetchSubCategories(searchText)
                              // );
                            }}
                            onSelect={this.selectSubCat}
                          />
                        </Col>
                        <Col className="pr-1" md="3">
                          <SelectOrganelle
                            handle_overlay={() => {
                              this.handleOverlayBrand();
                            }}
                            label={"Brand"}
                            onChange={(searchText) => {
                              this.setState(
                                {
                                  brandValue: {
                                    value: "",
                                    label: searchText,
                                  },
                                },
                                () => {
                                  this.getBrands(searchText);
                                }
                              );
                              // this.setState({ brandSearch: searchText });
                              this.getBrands(searchText);
                            }}
                            value={this.state.brandValue}
                            options={this.state.brands.map((brand) => {
                              return {
                                label: brand.title,
                                value: brand._id,
                              };
                            })}
                            onSelect={this.selectBrand}
                          />
                        </Col>
                        <Col className="pr-1" md="3">
                          <FormGroup>
                            <label>Select Tribe</label>
                            <br />
                            <select
                              className="form-control"
                              name="productFor"
                              required={false}
                              onChange={this.handleInput}
                            >
                              <option value="">
                                Select Sub Category First!
                              </option>
                              {this.state.productsFor.map((v, i) => (
                                <option key={i} value={v._id}>
                                  {v.category_name}
                                </option>
                              ))}
                            </select>
                          </FormGroup>
                        </Col>
                        <Col className="pr-1" md="3">
                          <FormGroup>
                            <label>Name</label>
                            <Input
                              placeholder="Name"
                              name="name"
                              onChange={this.handleInput}
                              required={true}
                              type="text"
                            />
                          </FormGroup>
                        </Col>

                        {/*      removed col of delivery mode                    
                       

                  

                         */}
                        <Col className="pr-1" md="3">
                          <FormGroup>
                            <label>Tax Type</label>
                            <br />
                            <select
                              className="form-control"
                              name="taxType"
                              required={true}
                              onChange={this.handleInput}
                            >
                              <option value="">Select an Option</option>
                              {this.state.taxTypes.map((v, i) => (
                                <option key={i} value={v._id}>
                                  {v.tax_type}
                                </option>
                              ))}
                            </select>
                          </FormGroup>
                        </Col>
                        {/* <Col className="pr-1" md="3">
                          <label htmlFor="Print Types">Print Types</label>
                          <MultiSelect
                            disableSearch
                            options={this.state.products}
                            value={this.state.productIds}
                            onChange={(data) => {
                              this.setState({ productIds: data });
                            }}
                            labelledBy={"Print Types"}
                          />
                          {this.state.warn ? (
                            <div>
                              <span style={{ color: "red" }}>*required</span>
                            </div>
                          ) : null}
                        </Col> */}

                        {/* <Col className="pr-1" md="3">
                          <FormGroup>
                            <label>Price</label>
                            <Input
                              placeholder="Price"
                              name="price"
                              onChange={this.handleInput}
                              required={true}
                              type="float"
                            />
                          </FormGroup>
                        </Col> */}
                        {/* <Col className="pr-1" md="3">
                          <FormGroup>
                            <label>Offer Type</label>
                            <br />
                            <select
                              className="form-control"
                              name="offerType"
                              onChange={this.handleInput}
                            >
                              <option value="">Select Option</option>
                              {["amount", "percent"].map((v, i) => (
                                <option key={i} value={v}>
                                  {v}
                                </option>
                              ))}
                            </select>
                          </FormGroup>
                        </Col> */}
                        {/* <Col className="pr-1" md="3">
                          <FormGroup>
                            <label>Offer Value</label>
                            <Input
                              placeholder="Offer Value"
                              name="value"
                              onChange={this.handleInput}
                              type="float"
                            />
                          </FormGroup>
                        </Col> */}
                        <Col className="pr-1" md="3">
                          <FormGroup>
                            <label>Delivery Charge</label>
                            <Input
                              placeholder="Delivery Charge"
                              name="charge"
                              onChange={this.handleInput}
                              required={false}
                              type="number"
                            />
                          </FormGroup>
                        </Col>

                        <Col className="pr-1" md="3">
                          <FormGroup>
                            <label>Short Description</label>
                            <Input
                              placeholder="Short Description"
                              value={this.state.short_description}
                              name="short_description"
                              onChange={this.handleInput}
                              required={false}
                              type="text"
                            />
                          </FormGroup>
                        </Col>

                        <Col className="pr-1" md="3">
                          <FormGroup>
                            <label>Product Return</label>
                            <select
                              className="form-control"
                              name="product_return"
                              value={this.state.product_return}
                              onChange={this.handleInput}
                              required={true}
                            >
                              <option value="">Product_Return</option>
                              <option value="yes">Yes</option>
                              <option value="no">No</option>
                            </select>
                          </FormGroup>
                        </Col>
                        <Col className="pr-1" md="3">
                          <FormGroup>
                            <label for="product_exchange">
                              Product Exchange
                            </label>
                            <select
                              className="form-control"
                              name="product_exchange"
                              // value={this.state.product_exchange}
                              onChange={this.handleInput}
                              required={true}
                            >
                              <option value="">Product_Exchange</option>
                              <option value="yes">Yes</option>
                              <option value="no">No</option>
                            </select>
                          </FormGroup>
                        </Col>

                        {/* <Col className="pr-1" md="3">
                        <label>Image</label>
                        <br />
                        <input
                          type="file"
                          name="image"
                          onChange={this.handleFile}
                          required={true}
                          accept="image/*"
                          multiple
                        />
                      </Col> */}

                        {/* <Col style={{ marginTop: "8px" }} md="3">
                          <label>Video</label>
                          <br />
                          <input
                            type="file"
                            onChange={this.handleVideo}
                            name="video"
                            accept="video/*"
                          />
                        </Col> */}

                        <Col style={{ marginTop: "8px" }} md="6">
                          <Grid container spacing={2}>
                            {/* removed image1 */}
                            {modalVisible === true ? (
                              <Modal
                                open={modalVisible}
                                onClose={() => {
                                  this.setState({
                                    modalVisible: false,
                                    pics: null,
                                    selectedImage: null,
                                  });
                                }}
                              >
                                <Box
                                  className="modal-size"
                                  sx={{
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    transform: "translate(-50%, -50%)",
                                    width: "62%",
                                    height: "72%",
                                    boxShadow: 24,
                                    p: 4,
                                    overflowY: "scroll",
                                  }}
                                  style={{
                                    justifyContent: "center",
                                    alignItems: "center",
                                    display: "flex",
                                  }}
                                >
                                  <Row
                                    style={{
                                      justifyContent: "center",
                                      alignItems: "center",
                                      marginTop: 20,
                                      width: 400,
                                      height: 400,
                                    }}
                                  >
                                    {/* <Col> */}
                                    <ReactCrop
                                      src={myArray[currenIndex]}
                                      crop={this.state.imageCrop}
                                      ruleOfThirds
                                      onImageLoaded={this.onImageLoaded}
                                      onComplete={this.onImageCropComplete}
                                      onChange={this.onCropChange}
                                      crossorigin="anonymous"
                                      aspect={4 / 3}
                                    />
                                    {/* </Col> */}
                                    {/* <Col>
                          {firstpic && currenIndex === 0? 
                                <img
                                alt="Crop"
                                style={{
                                  maxWidth: "100%",
                                  height: "auto",
                                  marginLeft: "1rem",
                                }}
                                src={firstpic}
                              />
                            : " "}
                            {secondpic && currenIndex === 1? 
                                <img
                                alt="Crop"
                                style={{
                                  maxWidth: "100%",
                                  height: "auto",
                                  marginLeft: "1rem",
                                }}
                                src={secondpic}
                              />
                            : " "}
                            {thirdpic && currenIndex === 2? 
                                <img
                                alt="Crop"
                                style={{
                                  maxWidth: "100%",
                                  height: "auto",
                                  marginLeft: "1rem",
                                }}
                                src={thirdpic}
                              />
                            : " "}
                            {fourthpic && currenIndex === 3? 
                                <img
                                alt="Crop"
                                style={{
                                  maxWidth: "100%",
                                  height: "auto",
                                  marginLeft: "1rem",
                                }}
                                src={fourthpic}
                              />
                            : " "}
                            {fifthpic && currenIndex === 4? 
                                <img
                                alt="Crop"
                                style={{
                                  maxWidth: "100%",
                                  height: "auto",
                                  marginLeft: "1rem",
                                }}
                                src={fifthpic}
                              />
                            : " "}

                    
                    </Col> */}
                                  </Row>

                                  {myArrayLength === currenIndex + 1 && (
                                    <Button
                                      color="primary"
                                      variant="contained"
                                      size="medium"
                                      style={{
                                        position: "absolute",
                                        top: "95%",
                                        right: "41%",
                                        transform: "translate(-50%, -50%)",
                                        zIndex: "10",
                                      }}
                                      type="submit"
                                      onClick={this.handleFinish}
                                    >
                                      Finish
                                    </Button>
                                  )}

                                  <Button
                                    color="primary"
                                    variant="contained"
                                    size="medium"
                                    style={{
                                      position: "absolute",
                                      top: "95%",
                                      right: "51%",
                                      transform: "translate(-50%, -50%)",
                                      zIndex: "10",
                                    }}
                                    onClick={this.handlePrev}
                                    disabled={currenIndex === 0}
                                  >
                                    Prev
                                  </Button>

                                  <div
                                    style={{
                                      position: "absolute",
                                      top: "10%",
                                      right: "22%",
                                      transform: "translate(-50%, -50%)",
                                      zIndex: "10",
                                      fontSize: 30,
                                      color: "black",
                                    }}
                                  >
                                    <h1
                                      style={{
                                        textAlign: "center",
                                        color: "black",
                                        fontSize: "2rem",
                                      }}
                                    >
                                      Crop Your Image
                                    </h1>
                                  </div>

                                  <Button
                                    color="primary"
                                    variant="contained"
                                    size="medium"
                                    style={{
                                      position: "absolute",
                                      top: "95%",
                                      right: "33%",
                                      transform: "translate(-50%, -50%)",
                                      zIndex: "10",
                                    }}
                                    onClick={this.handleNext}
                                    disabled={currenIndex === myArrayLength - 1}
                                  >
                                    Next
                                  </Button>
                                </Box>
                              </Modal>
                            ) : (
                              ""
                            )}
                          </Grid>
                        </Col>

                        {/* <Col className="pr-1" md="3">
                          <FormGroup>
                            <label>Return Product</label>
                            <select
                              className="form-control"
                              name="is_return"
                              value={this.state.is_return}
                              onChange={this.handleInput}
                            >
                              <option value="yes">Yes</option>

                              <option value="no">No</option>
                            </select>
                          </FormGroup>
                        </Col> */}
                        <Col md="12">
                          <label>Product Description</label>
                          <CKEditor
                            required={false}
                            editor={ClassicEditor}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              //console.log({ data });
                              this.setState({
                                overview: data,
                              });
                            }}
                          />
                          <br />
                        </Col>
                        <Col md="12">
                          <label>How to use</label>
                          <CKEditor
                            required={false}
                            editor={ClassicEditor}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              //console.log({ data });
                              this.setState({
                                note: data,
                              });
                            }}
                          />
                          <br />
                        </Col>
                        <Col md="12">
                          <label>Ingredients</label>
                          <CKEditor
                            required={false}
                            editor={ClassicEditor}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              //console.log({ data });
                              this.setState({
                                specification: data,
                              });
                            }}
                          />
                          <br />
                        </Col>
                        {/* removed customize product */}
                      </Row>
                      {/* 
removed customizable images col
                        <Col md="12">
                          <label>Description</label>
                          <CKEditor
                            editor={ClassicEditor}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              //console.log({ data });
                              this.setState({
                                description: data
                              });
                            }}
                          />
                          <br />
                        </Col>

                      </Row>) : null} */}
                      <Row>
                        <Col className="pr-1" md="6">
                          <Button
                            className="btn-round"
                            color="success"
                            type="submit"
                          >
                            {this.state.loading ? "Adding..." : "Add Product"}
                          </Button>
                          <Link to={`/admin/products`}>
                            <Button className="btn-round" color="danger">
                              Cancel
                            </Button>
                          </Link>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </>
      );
    }
  }
}

export default AddProduct;
