/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
// reactstrap components
import { Container } from "reactstrap";
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import AdminFooter from "components/Footers/AdminFooter.js";
import Sidebar from "components/Sidebar/Sidebar.jsx";
import init from "Helper/WindowToken";
import routes from "routes.js";
import AddCategory from "AddViews/AddCategory";
import UpdateCategory from "UpdateViews/UpdateCategory";
import AddSubCategory from "AddViews/AddSubCategory";
import UpdateSubCategory from "UpdateViews/UpdateSubCategory";
import AddPrintType from "AddViews/AddPrintType";
import UpdatePrintType from "UpdateViews/UpdatePrintType";
import AddVendor from "AddViews/AddVendor";
import UpdateVendor from "UpdateViews/UpdateVendor";
import AddProduct from "AddViews/AddProduct";
import UpdateProduct from "UpdateViews/UpdateProduct";
import AddDeliveryMode from "AddViews/AddDeliveryMode";
import UpdateDeliveryMode from "UpdateViews/UpdateDeliveryMode";
import AddLogistic from "AddViews/AddLogistic";
import UpdateLogistic from "UpdateViews/UpdateLogistics";
import AddCouponCode from "AddViews/AddCouponCode";
import UpdateCouponCode from "UpdateViews/UpdateCouponCode";
import AddAdvertisement from "AddViews/AddAdvertisement";
import UpdateAdvertisement from "UpdateViews/UpdateAdvertisement";
import AddTaxType from "AddViews/AddTaxType";
import UpdateTaxType from "UpdateViews/UpdateTaxType";
import AddPincode from "AddViews/AddPincode";
import UpdatePincode from "UpdateViews/UpdatePincode";
import AddHomePage from "AddViews/AddHomePage";
import UpdateHomePage from "UpdateViews/UpdateHomeImage";
import AddHomeSlider from "AddViews/AddHomeSlider";
import UpdateHomeSlider from "UpdateViews/UpdateHomeSlider";
import AddBestSeller from "AddViews/AddBestSeller";
import UpdateBestSeller from "UpdateViews/UpdateBestSeller";
import AddProductFor from "AddViews/AddProductFor";
import UpdateProductFor from "UpdateViews/UpdateProductFor";
import AddMobileSlider from "AddViews/AddMobileSlider";
import UpdateMobileSlider from "UpdateViews/UpdateMobileSlider";
import AddBulkOrder from "AddViews/AddBulkOrder";
import UpdateBulkOrder from "UpdateViews/UpdateBulkOrder";
import Variants from "views/Variants";
import AddVariants from "AddViews/AddVariants";
import UpdateVariant from "UpdateViews/UpdateVariant";
import AddSuperCategory from "AddViews/AddSuperCategories";
import UpdateSuperCategory from "UpdateViews/UpdateSuperCategories";
import AddReason from "AddViews/AddReason";
import UpdateReasons from "UpdateViews/UpdateReasons";
import AddOurInvestors from "AddViews/AddOurInvestor";
import UpdateOurInvestors from "UpdateViews/UpdateOurInvestors";
import AddDeliveryPartner from "AddViews/AddDeliveryPartner";
import UpdateDeliveryPartner from "UpdateViews/UpdateDeliveryPartner";
import AddSize from "AddViews/AddSize";
import UpdateSize from "UpdateViews/UpdateSize";
import AddColor from "AddViews/AddColor";
import UpdateColor from "UpdateViews/UpdateColor";
import AddPaymentImageSliders from "AddViews/AddPaymentImageSliders";
import UpdatePaymentImageSliders from "UpdateViews/UpdatePaymentImageSliders";
import AddExchangeProduct from "AddViews/AddExchangeProduct";
import UpdateExchangeProduct from "UpdateViews/UpdateExchangeProduct";
import ViewAllRating from "views/ViewAllRating";
import OrderDetails from "views/OrderDetails";
import UserDetails from "views/UserDetails";
import UpdateBrand from "UpdateViews/UpdateBrand";
import AddBrand from "AddViews/AddBrand";
import Inventory from "views/Inventory";
import AddRole from "views/AddRole";
import AddCollaborator from "views/AddCollaborator";
import LogIn from "components/Organelles/LogIn";
import AddSubAdmin from "AddViews/AddSubAdmin";
import UpdateSubAdmin from "UpdateViews/UpdateSubAdmin";
import axios from "axios";
import Constants from "variables/Constants";
import Password from "views/Password";
class Admin extends React.Component {
  componentDidUpdate(e) {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.mainContent.scrollTop = 0;
  }
  getRoutes = (routes) => {
    const user = localStorage.getItem("user");
    const usersPermission = localStorage.getItem("usersPermission");
    const user_parse = JSON.parse(user);
    let adminPermissions = [];
    if (user_parse.role === "admin") {
      adminPermissions = routes.map((route) => route.id);
    }
    if (user_parse.role === "subadmin") {
      const usersPermission_parse = JSON.parse(usersPermission);
      adminPermissions = usersPermission_parse?.menus;
    }
    if (adminPermissions.length === 0 && user_parse.role === "subadmin") {
      window.location.href = "/signin";
    }

    const permissions = adminPermissions;
    return routes.map((prop, key) => {
      if (prop.layout === "/admin" && permissions.includes(prop.id)) {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (
        this.props.location.pathname.indexOf(
          routes[i].layout + routes[i].path
        ) !== -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };
  render() {
    if (init() === "success") {
      return (
        <>
          {/* <button
            onClick={() => {
              console.log("click");
            }}
          >
            click
          </button> */}
          <div className="d-flex">
            <Sidebar
              {...this.props}
              routes={routes}
              logo={{
                innerLink: "/admin/index",
                imgSrc: require("assets/img/brand/argon-react.png"),
                imgAlt: "...",
              }}
            />
            <div className="main-content" ref="mainContent">
              {/* <AdminNavbar
                {...this.props}
                brandText={this.getBrandText(this.props.location.pathname)}
              /> */}
              <Switch>
                {this.getRoutes(routes)}
                <Route
                  path="/admin/addexchangeproduct"
                  component={AddExchangeProduct}
                />
                <Route path="/admin/password" component={Password} />
                <Route
                  path="/admin/updateexchangeproduct/:id"
                  component={UpdateExchangeProduct}
                />

                <Route
                  path="/admin/addpaymentimagesliders"
                  component={AddPaymentImageSliders}
                />
                <Route
                  path="/admin/updatepaymentimagesliders/:id"
                  component={UpdatePaymentImageSliders}
                />
                <Route path="/admin/addcolor" component={AddColor} />
                <Route path="/admin/updatecolor/:id" component={UpdateColor} />
                <Route path="/admin/addsize" component={AddSize} />
                <Route path="/admin/updatesize/:id" component={UpdateSize} />
                <Route path="/admin/addRole/" component={AddRole} />
                <Route
                  path="/admin/addCollaborator/"
                  component={AddCollaborator}
                />
                <Route
                  path="/admin/addourinvestors"
                  component={AddOurInvestors}
                />
                <Route
                  path="/admin/updateourinvestors/:id"
                  component={UpdateOurInvestors}
                />
                <Route
                  path="/admin/adddeliverypartner"
                  component={AddDeliveryPartner}
                />
                <Route path="/admin//variant/inventory" component={Inventory} />
                <Route
                  path="/admin/updatedeliverypartner/:id"
                  component={UpdateDeliveryPartner}
                />
                <Route path="/admin/addbulkorder" component={AddBulkOrder} />
                <Route
                  path="/admin/updatebulkorder/:id"
                  component={UpdateBulkOrder}
                />
                <Route
                  path="/admin/addmobilehomeslider"
                  component={AddMobileSlider}
                />
                <Route
                  path="/admin/updatemobilehomeslider/:id"
                  component={UpdateMobileSlider}
                />
                <Route path="/admin/addproductfor" component={AddProductFor} />
                <Route
                  path="/admin/updateproductfor/:id"
                  component={UpdateProductFor}
                />
                <Route path="/admin/addbestseller" component={AddBestSeller} />
                <Route
                  path="/admin/updatebestseller/:id"
                  component={UpdateBestSeller}
                />
                <Route path="/admin/addhomeslider" component={AddHomeSlider} />
                <Route
                  path="/admin/updatehomeslider/:id"
                  component={UpdateHomeSlider}
                />
                <Route path="/admin/addhomepage" component={AddHomePage} />
                <Route
                  path="/admin/updatehomepage/:id"
                  component={UpdateHomePage}
                />
                <Route path="/admin/addtaxtype" component={AddTaxType} />
                <Route
                  path="/admin/updatetaxtype/:id"
                  component={UpdateTaxType}
                />
                <Route path="/admin/addpincode" component={AddPincode} />
                <Route
                  path="/admin/updatepincode/:id"
                  component={UpdatePincode}
                />
                <Route path="/admin/addad" component={AddAdvertisement} />
                <Route
                  path="/admin/updatead/:id"
                  component={UpdateAdvertisement}
                />
                <Route path="/admin/addcouponcode" component={AddCouponCode} />
                <Route
                  path="/admin/updatecouponcode/:id"
                  component={UpdateCouponCode}
                />
                <Route path="/admin/addlogistic" component={AddLogistic} />
                <Route
                  path="/admin/updatelogistic/:id"
                  component={UpdateLogistic}
                />
                <Route
                  path="/admin/adddeliverymode"
                  component={AddDeliveryMode}
                />
                <Route
                  path="/admin/updatedeliverymode/:id"
                  component={UpdateDeliveryMode}
                />
                <Route path="/admin/addvendor" component={AddVendor} />
                <Route
                  path="/admin/updatevendor/:id"
                  component={UpdateVendor}
                />
                <Route path="/admin/addproduct" component={AddProduct} />
                <Route
                  path="/admin/updateproduct/:id"
                  component={UpdateProduct}
                />
                <Route path="/admin/addcategory" component={AddCategory} />
                <Route
                  path="/admin/updatecategory/:id"
                  component={UpdateCategory}
                />
                <Route path="/admin/addprinttype" component={AddPrintType} />
                <Route
                  path="/admin/updateprinttype/:id"
                  component={UpdatePrintType}
                />
                <Route
                  path="/admin/addsubcategory"
                  component={AddSubCategory}
                />
                <Route
                  path="/admin/updatesubcategory/:id"
                  component={UpdateSubCategory}
                />
                <Route path="/admin/variants/:id" component={Variants} />
                <Route path="/admin/viewallratings" component={ViewAllRating} />
                <Route path="/admin/addVariants/:id" component={AddVariants} />
                <Route
                  path="/admin/updateVariant/:id"
                  component={UpdateVariant}
                />
                <Route
                  path="/admin/addsupercategory"
                  component={AddSuperCategory}
                />
                <Route path="/admin/logIn" component={LogIn} />
                <Route path="/admin/addSubAdmin" component={AddSubAdmin} />
                <Route
                  path="/admin/updatesupercategory/:id"
                  component={UpdateSuperCategory}
                />
                <Route
                  path="/admin/updatesubAdmin/:id"
                  component={UpdateSubAdmin}
                />
                <Route path="/admin/updatebrand/:id" component={UpdateBrand} />
                <Route path="/admin/addreasons" component={AddReason} />
                <Route
                  path="/admin/updatereasons/:id"
                  component={UpdateReasons}
                />
                <Route
                  path="/admin/orderDetails/:id"
                  component={OrderDetails}
                />

                <Route path="/admin/userDetails/:id" component={UserDetails} />
                <Route path="/admin/addbrand" component={AddBrand} />
                <Redirect from="*" to="/admin/index" />
              </Switch>
              <Container fluid>{/* <AdminFooter /> */}</Container>
            </div>
          </div>
        </>
      );
    } else {
      window.location.href = "/signin";
    }
  }
}

export default Admin;
