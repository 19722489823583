// import React, { Component } from "react";
// import {
//   Card,
//   CardHeader,
//   CardBody,
//   CardTitle,
//   Row,
//   Col,
//   Button,
//   FormGroup,
//   Form,
//   Input,
//   UncontrolledAlert
// } from "reactstrap";
// import axios from "axios";
// import Constants from "../variables/Constants";
// import { Redirect, Link } from "react-router-dom";
// class AddPincode extends Component {
//   constructor(props) {
//     super(props);
//   this.state = {
//     pincode: 0,
//     charge: 0,
//     redirect: false,
//     warn: false,
//     notification: false,
//     loading: false,

//     // pinCode: '',
//       isValid: true,
//       message: "",
//   };
// }
// handlePinCodeChange = (event) => {
//   const { value } = event.target;
//   const sanitizedValue = value.replace(/\D/g, ''); // Remove non-digit characters
//   this.setState({ pincode: sanitizedValue });

//   // Validate pin code
//   const isValidPinCode = sanitizedValue.length === 6;
//   this.setState({ isValid: isValidPinCode });
// };

// handleFile = (e) => {
//   console.log(e.target.files);
//   this.setState({ [e.target.name]: e.target.files[0] });
//   console.log(this.state.image);
// };
//   handleInput = e => {
//     this.setState({
//       [e.target.name]: e.target.value
//     });
//   };
//   handleSubmit = e => {
//     this.setState({
//       loading: true
//     });
//     e.preventDefault();
//     let payLoad = {
//       pincode: this.state.pincode,
//       delivery_amount: this.state.charge
//     };
//     axios
//       .post(Constants.getUrls.pincodes, payLoad)
//      .then((resp) => {
//         if (resp.data.status === "success") {
//           setTimeout(() => {
//             this.setState({
//               redirect: true
//             });
//           }, 1000);
//           this.setState({
//             notification: true,
//             loading: false
//           });
//         } else {
//           this.setState({
//               warn:true,
//               loading:false
//           });
//           setTimeout(() => {
//             this.setState({
//               warn: false
//             });
//           }, 1000);
//         }
//       });
//   };
//   render() {

//     const { pincode, isValid } = this.state;
//     if (this.state.redirect) {
//       return <Redirect to={"/admin/pincodes"} />;
//     } else {
//       return (
//         // <>
//           <div className="content">
//             {this.state.notification ? (
//               <div style={{
//                 width:"250px",
//               position:"relative",
//               left:"40%",
//               top:"7px"}}>
//               <UncontrolledAlert color="success" fade={false}>
//               <span className="alert-inner--icon">
//                 <i className="ni ni-like-2" />
//               </span>{" "}
//               <span className="alert-inner--text">
//                 <strong>Pincode Added!</strong>
//               </span>
//             </UncontrolledAlert>
//             </div>
//             ) : null}
//             {this.state.warn ? (
//               <div style={{width:"250px",position:"relative",left:"40%",top:"7px"}}>
//               <UncontrolledAlert color="danger" fade={false}>
//               <span className="alert-inner--icon">
//                 <i className="ni ni-dislike-2" />
//               </span>{" "}
//               <span className="alert-inner--text">
//                 {/* <strong>Some Error Occured!</strong> */}
//                 <strong>This Pincode Already Added!!</strong>
//               </span>
//             </UncontrolledAlert>
//             </div>
//             ) : null}
//             <Row>
//               <Col md="12">
//                 <Card>
//                   <CardHeader>
//                     <CardTitle tag="h4">Add Pincode</CardTitle>
//                   </CardHeader>
//                   <CardBody>
//                     <Form onSubmit={this.handleSubmit}>
//                       <Row>
//                         <Col className="pr-1" md="6">
//                           <FormGroup>
//                             <label>Pincode</label>
//                             <Input
//                               placeholder="Pincode"
//                               name="pincode"
//                               // onChange={this.handleInput}
//                               value={this.state.pincode}
//                            onChange={this.handlePinCodeChange}
//                               required={true}
//                               type="number"
//                             />
//                              {!isValid && <p>Please enter a 6-digit pin code.</p>}
//                           </FormGroup>
//                         </Col>
//                         {/* <Col className="pr-1" md="6">
//                           <FormGroup>
//                             <label>Delivery Charge</label>
//                             <Input
//                               placeholder="Delivery Charge"
//                               name="charge"
//                               onChange={this.handleInput}
//                               required={true}
//                               type="number"
//                             />
//                           </FormGroup>
//                         </Col> */}
//                       </Row>
//                       <Row>
//                         <Col className="pr-1" md="6">
//                           <Button
//                             className="btn-round"
//                             color="success"
//                             type="submit"
//                           >
//                             {this.state.loading ? "Adding..." : "Add Pincode"}
//                           </Button>
//                           <Link to="/admin/pincodes">
//                             <Button className="btn-round" color="danger">
//                               Cancel
//                             </Button>
//                           </Link>
//                         </Col>
//                       </Row>
//                     </Form>
//                   </CardBody>
//                 </Card>
//               </Col>
//             </Row>
//           </div>
//         // </>
//       );
//     }
//   }
// }

// export default AddPincode;

import React, { Component } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Button,
  FormGroup,
  Form,
  Input,
  UncontrolledAlert,
} from "reactstrap";
import axios from "axios";
import Constants from "../variables/Constants";
import { Redirect, Link } from "react-router-dom";
import Modal from "react-awesome-modal";
import init from "../Helper/WindowToken";
const fd = new FormData();
export default class AddPincode extends Component {
  state = {
    pincode: 0,
    delivery_amount: 0,
    description: "",
    notification: false,
    loading: false,
    warn: false,
    message: "",
  };

  handleFile = (e) => {
    console.log(e.target.files);
    this.setState({ [e.target.name]: e.target.files[0] });
    console.log(this.state.image);
  };
  handleInput = (e) => {
    console.log(e.target.value);
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    this.setState({
      loading: true,
    });
    // let payLoad = {
    //   title: this.state.name,
    // };
    let fd = new FormData();
    fd.append("pincode", this.state.pincode);
    fd.append("delivery_amount", this.state.delivery_amount);
    // fd.append("image", this.state.image);
    console.log("here");
    axios.post(Constants.postUrls.postPincode, fd).then((resp) => {
      if (resp.data.status === "success") {
        setTimeout(() => {
          this.setState({
            redirect: true,
          });
        }, 1000);
        this.setState({
          notification: true,
          loading: false,
        });
      } else {
        this.setState({
          warn: true,
          loading: false,
        });
        setTimeout(() => {
          this.setState({
            warn: false,
          });
        }, 1000);
      }
    });
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={"/admin/pincodes"} />;
    } else {
      return (
        <div className="content">
          {this.state.notification ? (
            <div
              style={{
                width: "250px",
                position: "relative",
                left: "40%",
                top: "7px",
              }}
            >
              <UncontrolledAlert color="success" fade={false}>
                <span className="alert-inner--icon">
                  <i className="ni ni-like-2" />
                </span>{" "}
                <span className="alert-inner--text">
                  {/* <strong> {this.state.message} </strong> */}
                  <strong>Pincode Added!</strong>
                </span>
              </UncontrolledAlert>
            </div>
          ) : null}

          {this.state.warn ? (
            <div
              style={{
                width: "250px",
                position: "relative",
                left: "40%",
                top: "7px",
              }}
            >
              <UncontrolledAlert color="danger" fade={false}>
                <span className="alert-inner--icon">
                  <i className="ni ni-dislike-2" />
                </span>{" "}
                <span className="alert-inner--text">
                  {/* <strong>Some Error Occured!</strong> */}
                  <strong>This Pincode Already Added!</strong>
                </span>
              </UncontrolledAlert>
            </div>
          ) : null}

          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">Add Pincode</CardTitle>
                </CardHeader>
                <CardBody>
                  <Form onSubmit={this.handleSubmit}>
                    <Row>
                      <Col className="pr-1" md="6">
                        <FormGroup>
                          <label>Pincode</label>
                          <Input
                            placeholder="Pincode"
                            value={this.state.pincode}
                            name="pincode"
                            onChange={this.handleInput}
                            required={true}
                            type="number"
                          />
                        </FormGroup>
                      </Col>
                      {/* <Col>
                        <FormGroup>
                          <label>Description</label>
                          <Input
                            placeholder="Description"
                            value={this.state.description}
                            name="description"
                            onChange={this.handleInput}
                            required={true}
                            type="text"
                          />
                        </FormGroup>
                      </Col> */}
                      {/* <Col className="pr-1" md="6">
                        <label>Image</label>
                        <br />
                        <input
                          type="file"
                          name="image"
                          onChange={this.handleFile}
                          // required={true}
                          accept="image/*"
                        />
                      </Col>

                      <Col className="pr-1" md="6">
                          <label>Uploaded Image</label>
                          <br />
                          <img
                            src={Constants.imgUrl + this.state.image}
                            alt="image"
                          />
                        </Col>  */}
                    </Row>
                    <Row>
                      <Col className="pr-1" md="6">
                        <Button
                          className="btn-round"
                          color="success"
                          type="submit"
                        >
                          {this.state.loading ? "Adding..." : "Add pincode"}
                        </Button>
                        <Link to="/admin/pincodes">
                          <Button className="btn-round" color="danger">
                            Cancel
                          </Button>
                        </Link>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      );
    }
  }
}
