import React, { Component } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Input,
  Table,
  Row,
  Col,
  Button,
  UncontrolledAlert,
  Pagination,
  PaginationItem,
  PaginationLink,
  FormGroup,
} from "reactstrap";
import axios from "axios";
import Constants from "../variables/Constants";
import { Link } from "react-router-dom";
import Modal from "react-awesome-modal";
import { IconButton } from "@material-ui/core";
import "../css/ToolTipStyle.css";
import {
  faArrowTrendUp,
  faInfo,
  faInfoCircle,
  faPen,
  faPlus,
  faSadTear,
  faShirt,
  faStar,
  faStarAndCrescent,
  faStarHalf,
  faStarHalfAlt,
  faTrash,
  faTrophy,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSadCry } from "@fortawesome/free-regular-svg-icons";

class Inventory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inventoryData: [],
      buttonColor: "success",
      data: [],
      productIds: [],
      products: [],
      all_filter_product: [],
      selectedID: "",

      warn: false,
      redirect: false,
      warning: false,
      notification: false,
      loading: false,
      super_categories: [],
      super_category: "",
      __superCategories: [],
      __categories: [],
      __subCategories: [],
      categories: [],
      category: "",
      sub_categories: [],
      sub_category: "",
      brands: [],
      superCategoryValue: "",
      categoryValue: "",
      subCategoryValue: "",
      brandValue: "",

      notifyStatus: false,
      notifyDelete: false,
      visible: false,

      searching: false,
      searched: false,
      delLoading: false,

      trendId: 0,
      notifyTrending: false,
      visibleTrending: false,
      trending: false,
      trendingStatus: "",

      bestSellerId: 0,
      notifyBestSellers: false,
      visibleBestSellers: false,
      bestsellers: false,
      bestSellersStatus: "",
      rowId: 0,
      status: "",

      active: 1,
      perPage: "10",
      currentPage: 1,
      prevPage: 0,
      nextPage: 0,
      lastPage: 0,
      lastPageView: false,
      firstPageView: false,
      total: 0,
      from: 0,
      to: 0,
      fromInc: 1,
      toInc: 5,
      btnIdArray: [],
      filteredBtnIdArray: [],
      productId: "",
      dropdown: false,
    };
  }
  componentDidMount() {
    this.getInventory();
  }
  getInventory = () => {
    axios
      .get(`${Constants.getUrls.getInventory}?limit=100`)
      .then((response) => {
        console.log(response.data[0]?.paginatedResults, "inventory");
        console.log(response, "inventory-pages");
        this.setState({ inventoryData: response.data[0]?.paginatedResults });
      });
  };

  handleModal = (sec, arg, status) => {
    this.setState({
      rowId: sec._id,
      trendId: sec._id,

      trendingStatus: status,
      bestSellersStatus: status,

      bestSellerId: sec._id,
      visible: arg === "bestsellers" ? false : true,
      visibleBestSellers: arg === "bestsellers" ? true : false,

      visible: arg === "trending" ? false : true,
      visibleTrending: arg === "trending" ? true : false,
    });
  };

  handleSearch = (e) => {
    const value = e.target.value;
    const newArray = this.state.inventoryData.filter((item) =>
      item.variants.product.name.toLowerCase().includes(value.toLowerCase())
    );
    console.log(newArray);
    this.setState({ inventoryData: newArray });
  };
  clearSearch = () => {
    let input = document.querySelector(".input-search");
    input.value = "";
  };

  render() {
    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader className="bg-black">
                  <CardTitle className="table-content-text" tag="h4">
                    Inventory
                  </CardTitle>
                  <Row className="justify-content-center">
                    <Input
                      className="input-search"
                      style={{ width: "20%" }}
                      onChange={(e) => {
                        this.handleSearch(e);
                      }}
                      placeholder="search"
                    ></Input>
                    <button
                      onClick={() => {
                        this.clearSearch();

                        this.getInventory();
                      }}
                    >
                      X
                    </button>
                  </Row>
                </CardHeader>
                <CardBody className="bg-black shadow-sm">
                  {/*/////// main-row /////// */}

                  {this.state.inventoryData.map((data, index) => (
                    <div>
                      <table className="table responsive bg-black borderless main-table">
                        <table className="tablesorter table bg-black borderless">
                          <thead className="borderless">
                            <tr className="table-head-row d-flex align-items-center">
                              <th className="col-1">{index + 1}</th>
                              <th className="col-1 text-center">
                                <img
                                  src={Constants.imgUrl + data?.variants?.image}
                                  alt=""
                                />
                              </th>
                              <th className="col-4">
                                {data.variants?.product?.name}
                              </th>

                              <th className="col-2">
                                Total Variants:
                                {data.totalVariants}
                              </th>
                              <th className="col-2">
                                In Stock: {data.totalQuantity}
                              </th>
                              <th>
                                Variants: {data.variants.variant_quantity}
                              </th>
                            </tr>
                          </thead>
                        </table>
                      </table>
                    </div>
                  ))}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default Inventory;
