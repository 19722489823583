import React, { useEffect, useState } from "react";
import image from "../../login.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faLock } from "@fortawesome/free-solid-svg-icons";

function LogIn() {
  const [isClicked, setIsClicked] = useState(false);
  const [inputName, setInputName] = useState("");

  useEffect(() => {
    // inputClicked();
    console.log(inputName);
  }, [inputName]);

  const inputClicked = (e) => {
    console.log(e.target.name, "e ");
    e.target.name === inputName ? setIsClicked(true) : setIsClicked(false);
  };
  const inputBlured = () => {
    setIsClicked(false);
  };

  return (
    <div className="log-in d-flex align-items-center justify-content-center p-5">
      <div
        style={{
          height: "500px",
          width: "400px",
          borderRadius: "10px",
          background: "#f3f3f3cc",
          boxShadow: "7px 7px 10px #cbced1, -7px -7px 10px white",
          border: "4px solid #f3f3f3cc",
          color: "#525f7f",
          position: "relative",
          overflow: "hidden",
        }}
        className=" d-flex  flex-column align-items-start"
      >
        <img
          src={image}
          style={{
            transform: "rotate(219deg)",
            width: "360px",
            position: "absolute",
            top: "-56px",
            left: "-28px",
          }}
          alt=""
        />
        <span
          style={{
            position: "absolute",
            top: "-4px",
            left: "0px",
            fontSize: "100px",
            fontWeight: "900",
            color: "white",
          }}
        >
          log in
        </span>
        <div
          style={{
            position: "absolute",
            top: "50%",
            display: "flex",
            flexDirection: "column",
            width: "100%",
            padding: "20px",
            alignItems: "center",
          }}
        >
          <input
            type="text"
            style={{
              width: "100%",
              backgroundColor: "#ecf0f3",
              boxShadow: "inset 6px 6px 6px #cbced1, inset -6px -6px 6px white",
              fontSize: "14px",
              height: "50px",
              padding: "10px 10px 10px 20px",
              border: "none",
              borderRadius: "10px",
              marginBottom: "10px",
            }}
            name="input1"
            placeholder="Username"
            onClick={(e) => {
              setInputName(e.target.name);
              inputClicked(e);
            }}
            className={`${isClicked ? "clicked" : ""}`}
          />
          <FontAwesomeIcon
            style={{
              position: "absolute",
              top: "96px",
              left: "35px",
              color: "#00000024",
            }}
            icon={faEnvelope}
          />
          <input
            type="text"
            style={{
              width: "100%",
              backgroundColor: "#ecf0f3",
              boxShadow: "inset 6px 6px 6px #cbced1, inset -6px -6px 6px white",
              fontSize: "14px",
              height: "50px",
              padding: "10px 10px 10px 20px",
              border: "none",
              borderRadius: "10px",
            }}
            name="input2"
            placeholder="Password"
            onClick={(e) => {
              setInputName(e.target.name);
              inputClicked(e);
            }}
            className={`${isClicked ? "clicked" : ""}`}
          />
          <FontAwesomeIcon
            style={{
              position: "absolute",
              top: "35px",
              left: "35px",
              color: "#00000024",
            }}
            icon={faLock}
          />

          <button
            style={{
              padding: "10px 20px",
              boxShadow: "6px 6px 6px #cbced1, -6px -6px 6px white",
              borderRadius: "10px",
              border: "none",
              width: "50%",
              marginTop: "50px",
              backgroundColor: "#5b3f98ba",
              color: "white",
            }}
          >
            Log In
          </button>
        </div>
      </div>
    </div>
  );
}

export default LogIn;
