import { faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SelectOrganelle from "components/Organelles/SelectOrganelle";
import React from "react";
import { Link } from "react-router-dom/cjs/react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Row,
  Form,
  FormGroup,
  Input,
  Table,
  Button,
} from "reactstrap";

function AddCollaborator() {
  return (
    <Row>
      <Col md={12}>
        <CardBody>
          <Row>
            <Col md={6}>
              <Form>
                <FormGroup>
                  <Input className="mt-2" placeholder="name">
                    Name
                  </Input>
                  <Input className="mt-2" type="number" placeholder="mobile">
                    Mobile
                  </Input>
                  <Input className="mt-2" type="email" placeholder="@mail.com">
                    Email
                  </Input>
                  {/* <Input className="mt-2" placeholder="role"></Input> */}
                  <div className="mt-3">
                    <SelectOrganelle label={"Role"} />
                  </div>
                </FormGroup>
              </Form>
              <Button className="btn btn-success">Submit</Button>
            </Col>
          </Row>
        </CardBody>
      </Col>
    </Row>
  );
}

export default AddCollaborator;
