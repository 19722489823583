import React, { Component } from "react";
import axios from "axios";
import Constants from "../variables/Constants";
import { Redirect, Link } from "react-router-dom";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Button,
  FormGroup,
  Form,
  Input,
  UncontrolledAlert,
} from "reactstrap";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import InputLabel from "@material-ui/core/InputLabel";

import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";

const classes = makeStyles(styles);

class AddSuperCategory extends Component {
  state = {
    name: "",
    redirect: false,
    warn: false,
    notification: false,
    loading: false,
    super_category: "",
    data: [],
    image: "",
    stickerImage: "",
    // image preview
    imageFile: "",
    stickerFile: "",
    photoUrl: "",
    stickerUrl: "",
    imagePreview: false,
    stickerPreview: false,
  };

  handleFile = (e) => {
    this.setState({ [e.target.name]: e.target.files[0] });
    console.log(e.target.files[0]);
  };
  // for image preview
  handlePreview = (e) => {
    this.setState({ imageFile: e.target.files[0] }, () => {
      if (this.state.imageFile) {
        const reader = new FileReader();
        reader.onload = () => {
          this.setState({ photoUrl: reader.result });
        };
        reader.readAsDataURL(this.state.imageFile);
      }
    });
  };

  // for sticker preview
  handlePreview2 = (e) => {
    this.setState({ stickerFile: e.target.files[0] }, () => {
      if (this.state.stickerFile) {
        const reader = new FileReader();
        reader.onload = () => {
          this.setState({ stickerUrl: reader.result });
        };
        reader.readAsDataURL(this.state.stickerFile);
      }
    });
  };

  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleSubmit = (e) => {
    console.log("checking");
    this.setState({
      loading: true,
    });
    e.preventDefault();
    // let payLoad = {
    //   title: this.state.name,
    // };
    let fd = new FormData();

    fd.append("title", this.state.name);
    fd.append("image", this.state.image);
    fd.append("stickerImage", this.state.stickerImage);
    axios.post(Constants.getUrls.super_categories, fd).then((resp) => {
      console.log(resp.data);
      if (resp.data.status === "success") {
        fd.delete("image");
        setTimeout(() => {
          this.setState({
            redirect: true,
          });
        }, 1000);
        this.setState({
          notification: true,
          loading: false,
        });
      } else {
        this.setState({
          warn: true,
          loading: false,
        });
        setTimeout(() => {
          this.setState({
            warn: false,
          });
        }, 1000);
      }
    });
  };
  render() {
    if (this.state.redirect) {
      return <Redirect to={"/admin/supercategories"} />;
    } else {
      return (
        <>
          <div className="content">
            {this.state.notification ? (
              <div
                style={{
                  width: "250px",
                  position: "relative",
                  left: "40%",
                  top: "7px",
                }}
              >
                <UncontrolledAlert color="success" fade={false}>
                  <span className="alert-inner--icon">
                    <i className="ni ni-like-2" />
                  </span>{" "}
                  <span className="alert-inner--text">
                    <strong>Super Category Added!</strong>
                  </span>
                </UncontrolledAlert>
              </div>
            ) : null}
            {this.state.warn ? (
              <div
                style={{
                  width: "250px",
                  position: "relative",
                  left: "40%",
                  top: "7px",
                }}
              >
                <UncontrolledAlert color="danger" fade={false}>
                  <span className="alert-inner--icon">
                    <i className="ni ni-dislike-2" />
                  </span>{" "}
                  <span className="alert-inner--text">
                    {/* <strong>Some Error Occured!</strong> */}
                    <strong>This SuperCategory Already Added!</strong>
                  </span>
                </UncontrolledAlert>
              </div>
            ) : null}
            <Row>
              <Col md="12">
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">Add Super Category</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Form onSubmit={this.handleSubmit}>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={3}>
                          <CustomInput
                            style={{ textTranform: "capitalize" }}
                            labelText="Name"
                            id="float"
                            inputProps={{
                              required: true,
                              onChange: (e) =>
                                this.setState({ name: e.target.value }),
                            }}
                            formControlProps={{
                              fullWidth: true,
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                          <Row>
                            <GridItem xs={12} sm={12} md={6}>
                              <label>Image (500 X 500)</label>
                              <br />
                              <input
                                type="file"
                                onChange={(e) => {
                                  this.handleFile(e);
                                  this.handlePreview(e);
                                  this.setState({ imagePreview: true });
                                }}
                                required={true}
                                name="image"
                              />
                            </GridItem>
                            {this.state.imagePreview ? (
                              <GridItem xs={12} sm={12} md={6}>
                                <label>Image Preview</label>
                                <br />
                                <img
                                  width={100}
                                  src={this.state.photoUrl}
                                  alt=""
                                />
                              </GridItem>
                            ) : null}
                            {/* image preview onupload */}
                          </Row>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                          <Row>
                            <GridItem xs={12} sm={12} md={6}>
                              <label>Sticker (100 X 100)</label>
                              <br />
                              <input
                                type="file"
                                onChange={(e) => {
                                  this.setState({
                                    stickerImage: e.target.files[0],
                                  });
                                  this.handlePreview2(e);
                                  this.setState({ stickerPreview: true });
                                }}
                                required={true}
                                name="stickerImage"
                              />
                            </GridItem>
                            {this.state.stickerPreview ? (
                              <GridItem xs={12} sm={12} md={6}>
                                <label>Sticker Preview</label>
                                <br />
                                <img
                                  width={100}
                                  src={this.state.stickerUrl}
                                  alt=""
                                />
                              </GridItem>
                            ) : null}
                            {/* Sticker preview onupload */}
                          </Row>
                        </GridItem>
                      </GridContainer>

                      <Row className="mt-5">
                        <Col className="pr-1" md="6">
                          <Button
                            className="btn-round"
                            color="success"
                            type="submit"
                          >
                            {this.state.loading ? "Adding..." : "Add Category"}
                          </Button>
                          <Link to="/admin/supercategories">
                            <Button className="btn-round" color="danger">
                              Cancel
                            </Button>
                          </Link>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </>
      );
    }
  }
}

export default AddSuperCategory;
