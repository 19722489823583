import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import Constants from "variables/Constants";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { faCopy } from "@fortawesome/free-regular-svg-icons";

function OrderDetails() {
  const parameter = useParams();

  const parameterId = parameter.id;
  const [order, setOrder] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    axios.get(`${Constants.getUrls.orders}/${parameterId}`).then((response) => {
      console.log(response.data, "order-details");
      setOrder(response.data);
    });
  };

  const [copied, setCopied] = useState(false);

  return (
    <>
      <div className="card px-2">
        <div className="card-header">
          <h6 className="mb-0">Order Details</h6>
        </div>
        <div className="card-body d-flex justify-content-between row">
          <h5>
            <span className="text-muted">Order ID : </span>
            <span className="fw-bold">{order[0]?.orders[0]?.order_id}</span>
          </h5>
          <h5 className="col-6">
            <span className="text-muted">Order Date : </span>
            <span className="fw-bold">
              {Constants.days[
                new Date(order[0]?.orders[0]?.order_date).getDay()
              ] +
                " " +
                new Date(order[0]?.orders[0]?.order_date).getDate() +
                " " +
                Constants.month[
                  new Date(order[0]?.orders[0]?.order_date).getMonth()
                ] +
                " " +
                new Date(order[0]?.orders[0]?.order_date).getFullYear() +
                ", " +
                new Date(order[0]?.orders[0]?.order_date).getHours() +
                ":" +
                new Date(order[0]?.orders[0]?.order_date).getMinutes()}
            </span>
          </h5>
          <h5 className="col-6">
            <span className="text-muted">Payment Status : </span>
            <span
              className={`fw-bold text-uppercase ${
                order[0]?.orders[0]?.payment_status === "success" &&
                "text-success"
              }`}
            >
              {order[0]?.orders[0]?.payment_status}
            </span>
          </h5>
          <h5 className="col-6">
            <span className="text-muted">Payment Mode : </span>
            <span className="fw-bold text-uppercase">
              {order[0]?.orders[0]?.payment_mode}
            </span>
          </h5>
          <h5 className="col-6">
            <span className="text-muted">Order Status : </span>
            <span className="fw-bold text-uppercase">
              {order[0]?.orders[0]?.delivery_status}
            </span>
          </h5>
          <h5 className="col-6">
            <span className="text-muted">Delivery Charges : </span>
            <span className="fw-bold text-uppercase text-success">
              ₹ {order[0]?.orders[0]?.delivery_amount}
            </span>
          </h5>
          <h5 className="col-6">
            <span className="text-muted">Amount : </span>
            <span className="fw-bold text-uppercase text-success">
              ₹ {order[0]?.orders[0]?.total}
            </span>
          </h5>
        </div>
      </div>
      <div className="card px-2 mt-4">
        <div className="card-header d-flex justify-content-between">
          <h6 className="mb-0">Address Details</h6>
          <div>
            <CopyToClipboard
              text={
                order[0]?.orders[0]?.address?.first_name +
                " " +
                order[0]?.orders[0]?.address?.last_name +
                " " +
                order[0]?.orders[0]?.address?.mobile_no +
                " " +
                order[0]?.orders[0]?.address?.address_line1 +
                " " +
                order[0]?.orders[0]?.address?.address_line2 +
                " " +
                order[0]?.orders[0]?.address?.pincode
              }
              onCopy={() => {
                setCopied(true);
                setTimeout(() => {
                  setCopied(false);
                }, 3000);
              }}
            >
              <FontAwesomeIcon icon={copied ? faCheck : faCopy} />
            </CopyToClipboard>
          </div>
        </div>
        <div className="card-body row">
          <h5 className="col-6">
            <span className="text-muted">Name :</span>
            <span className={`fw-bold text-uppercase`}>
              {" "}
              {order[0]?.orders[0]?.address?.first_name}{" "}
              {order[0]?.orders[0]?.address?.last_name}
            </span>
          </h5>
          <h5 className="col-6">
            <span className="text-muted">Mobile No. :</span>
            <span className={`fw-bold text-uppercase`}>
              {order[0]?.orders[0]?.address?.mobile_no}
            </span>
          </h5>
          <h5 className="col-6">
            <span className="text-muted">Address: </span>
            <span className="fw-bold">
              {order[0]?.orders[0]?.address?.address_line1},
              {order[0]?.orders[0]?.address?.address_line2}
            </span>
          </h5>
          <h5 className="col-6">
            <span className="text-muted">City: </span>
            <span className="fw-bold">
              {" "}
              {order[0]?.orders[0]?.address?.city}
            </span>
          </h5>
          <h5 className="col-6">
            <span className="text-muted">Pincode: </span>
            <span className="fw-bold">
              {order[0]?.orders[0]?.address?.pincode}
            </span>
          </h5>
        </div>
      </div>
      {order[0]?.orders?.map((orderItem) => (
        <div className="card px-2 mt-4">
          <div className="row">
            <div className="col-lg-8">
              <div className="card mt-4">
                <div className="card-header">
                  <h6 className="mb-0">Product Details</h6>
                </div>
                <div className="card-body row">
                  <div className="col-4">
                    <h5>
                      <span className="fw-bold">
                        <Link
                          to={
                            "/product_details/" +
                            orderItem.product?._id +
                            "?variant=" +
                            orderItem.variant?._id
                          }
                        >
                          {orderItem.product?.name}
                        </Link>
                      </span>
                    </h5>
                    <h5>
                      <span className="text-muted">Quantity :</span>
                      <span className={`fw-bold text-uppercase`}>
                        {orderItem.quantity}
                      </span>
                    </h5>
                    <h5>
                      <span className="text-muted">MRP : </span>
                      <span className="fw-bold text-uppercase text-success">
                        ₹ {orderItem.mrp_price}
                      </span>
                    </h5>
                    <h5>
                      <span className="text-muted">Selling Price : </span>
                      <span className="fw-bold text-uppercase text-success">
                        ₹ {orderItem.selling_price}
                      </span>
                    </h5>
                  </div>
                  <div className="col-4">
                    <h5>
                      <span className="text-muted">Color : </span>
                      <span className="fw-bold text-uppercase">
                        {" "}
                        {orderItem.variant?.color[0]?.color}
                      </span>
                    </h5>
                    <h5>
                      <span className="text-muted">Size : </span>
                      <span className="fw-bold text-uppercase">
                        {" "}
                        {orderItem.variant?.size[0]?.size}
                      </span>
                    </h5>
                  </div>
                  <div className="col-4">
                    <img
                      width="100"
                      src={
                        orderItem?.variant?.image
                          ? Constants.imgUrl + orderItem?.variant?.image[0]
                          : ""
                      }
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
}

export default OrderDetails;
